import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import styles from './layout.module.scss';
import { IOption } from '~/types/options';
import { phasesMenu } from '../estimate/PhaseSidebar';

const LayoutAdmin = ({ children }: { children: React.ReactNode }) => {
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable container
  const location = useLocation();

  useEffect(() => {
    if (phasesMenu.find((p: IOption) => p.value === location.hash)) {
      const element : any = document.querySelector(location.hash);
      if (element?.offsetTop && containerRef.current) {
        containerRef.current?.scrollTo({
          top: element?.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);
  return (
  <div className={styles.layout}>
    <Navbar />
    <div ref={containerRef} id='layout' className={styles.global}>
      {children}
    </div>
  </div>
  );
};

export default LayoutAdmin;
