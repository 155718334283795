import React from 'react';
import { useParams } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import { IoTrash } from 'react-icons/io5';
import { LuDownload } from 'react-icons/lu';
import { useWindowSize } from 'usehooks-ts';
import styles from './ged.module.scss';
import {
  useAddWorksiteFile, useDeleteWorksiteFile, useGetWorksite, useGetWorksitesList,
} from '~/hooks/worksites';
import { IOption } from '~/types/options';
import { WorksiteFile } from '~/types/worksites';
import { downloadFile } from '~/utils';

const FileRow = ({ file, worksiteId }: { file: WorksiteFile, worksiteId: string }) => {
  const {
    mutate: deletFile,
  } = useDeleteWorksiteFile();
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { width } = useWindowSize();
  const formattedDate = (date: Date) => (new Intl.DateTimeFormat('fr', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(date));

  function formatFileSize(sizeInKo: number): string {
    if (sizeInKo >= 1000000) {
      const sizeInMo = sizeInKo / 1000000;
      return `${sizeInMo.toFixed(2)} MO`;
    }
    const sizeInKoRound = sizeInKo / 1000;
    return `${sizeInKoRound.toFixed(0)} KO`;
  }
  if (width <= 1024) {
    return <div className={styles.card}>
      <div className={styles.infos}>
        {file.file.mimetype === 'image/png'
          && <div className={styles.picture}>
            <img src={`${process.env.REACT_APP_API_URL}/files/public/${file.file.path}?token=${token}`} alt='picture' />
          </div>
        }
        <div className={styles.title}>
          <h3>{file?.file?.name}</h3>
          <p>{file.createdAt && formattedDate(new Date(file.createdAt))}</p>
        </div>
      </div>
      <div className={styles.actions}>
        <a
            onClick={() => downloadFile(`${process.env.REACT_APP_API_URL}/files/public/${file.file.path}?token=${token}`, file.file.name)}
            download
          >
          <LuDownload />
        </a>
        <button
          type="button"
          onClick={() => deletFile({
            fileId: file.file?._id,
            worksiteId,
          })}
        >
          <IoTrash />
        </button>
      </div>
    </div>;
  }

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {file.file.mimetype === 'image/png'
          && <div className={styles.picture}>
            <img src={`${process.env.REACT_APP_API_URL}/files/public/${file.file.path}?token=${token}`} alt='picture' />
          </div>
        }
        <p>{file?.file?.name}</p>
      </div>
      <div className={styles.col}>
        <p>{file.author?.profile.firstName} {file.author?.profile.lastName}</p>
      </div>
      <div className={styles.col}>
        <p>{file.createdAt && formattedDate(new Date(file.createdAt))}</p>
      </div>
      <div className={styles.col}>
        <p>{file?.size && formatFileSize(file.size)}</p>
      </div>
      <div className={styles.col}>
        <a
          onClick={() => downloadFile(`${process.env.REACT_APP_API_URL}/files/public/${file.file.path}?token=${token}`, file.file.name)}
          download
        >
          <LuDownload />
        </a>
        <button
          type="button"
          onClick={() => deletFile({
            fileId: file.file?._id,
            worksiteId,
          })}
        >
          <IoTrash />
        </button>
      </div>
    </div>
  );
};

const GedCategory = () => {
  const { id, worksiteId, type } = useParams();
  const {
    data: worksite,
  } = useGetWorksite(id || worksiteId || '');

  const {
    data: list = {},
  } = useGetWorksitesList();

  const {
    mutateAsync: addWorksiteFile,
  } = useAddWorksiteFile();

  const categoryLabel = list?.filesTypes?.find((f:IOption) => f.value === type)?.label;
  const files = worksite?.files?.filter((f: WorksiteFile) => f.type === type);
  return (
    <div className={styles.ged}>
      <div className={styles['title-desktop']}>
        <div className={styles.add}>
          <label htmlFor={type}>
            <BiPlus />
            Ajouter un fichier
          </label>
          <input id={type} type="file" name="photo"
            onChange={async (e) => {
              const file = e?.target?.files && e?.target?.files[0];
              await addWorksiteFile({
                _id: worksite?._id,
                file,
                type,
              });
            }}
          />
          <p>{worksite?.estimate?.worksite?.name} / {categoryLabel}</p>
        </div>
      </div>
      <div className={styles['title-mobile']}>
        <div className={styles.title}>
          <h1>{worksite?.estimate?.worksite?.name}</h1>
          <h2>{categoryLabel}</h2>
        </div>
        <label htmlFor={type}>
          <BiPlus />
        </label>
        <input id={type} type="file" name="photo"
          onChange={async (e) => {
            const file = e?.target?.files && e?.target?.files[0];
            await addWorksiteFile({
              _id: worksite?._id,
              file,
              type,
            });
          }}
        />
      </div>
      <div className={styles.files}>
        {worksite?._id && files?.map((f: WorksiteFile) => (<FileRow
          key={f?._id}
          worksiteId={worksite?._id}
          file={f}
        />))}
      </div>
    </div>
  );
};

export default GedCategory;
