import React from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './memberRequestForm.module.scss';
import { useCreateRequest, useGetRequestsList } from '~/hooks/requests';
import RequestForm from '~/components/worksite/form/RequestForm';
import { useGetWorksite } from '~/hooks/worksites';

const MemberRequestForm = () => {
  const { worksiteId, type } = useParams();
  const { data: worksite } = useGetWorksite(worksiteId || '');
  const { data: list } = useGetRequestsList();
  const navigate = useNavigate();
  const {
    isLoading,
    mutateAsync: createRequest,
  } = useCreateRequest('La demande a été enregistré');
  const methods = useForm<FieldValues>({
    defaultValues: {
      worksite: worksiteId,
      type,
      content: {},
    },
  });

  const template = list?.templates?.find((t: any) => t.type === type);

  const onSubmit = async (data: FieldValues) => {
    const form = { ...data };
    if (form?.content?.date) {
      form.content.date = new Date(form.content.date);
    }
    if (form?.content?.medicalVisitDate) {
      form.content.date = new Date(form.content.date);
    }
    const res = await createRequest(form);
    if (res) {
      console.log(res);
      navigate(-1);
    }
  };

  return (
    <>
    <div className={styles.request}>
      <h1>
        {worksite?.estimate?.worksite.name}
      </h1>
      <h2>
        {template?.label}
      </h2>
      <FormProvider {...methods}>
        <RequestForm
          onSubmit={onSubmit}
          template={template}
          isLoading={isLoading}
        />
      </FormProvider>
    </div>
    <div className={styles.illu}>

    </div>
    </>
  );
};

export default MemberRequestForm;
