import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import styles from './worksiteNav.module.scss';

const NavIcon = ({ type = 'infos', active = false }: { type: string, active: boolean }) => {
  if (type === 'ged') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M23.4209 13.1201L21.8584 5.70752C21.5566 4.09033 20.3945 3.125 18.75 3.125H6.25C5.42969 3.125 4.73633 3.354 4.19434 3.80566C3.65235 4.25732 3.29737 4.89502 3.14063 5.70459L1.57813 13.1201C1.56757 13.1732 1.56234 13.2272 1.5625 13.2812V18.75C1.5625 19.5788 1.89174 20.3737 2.4778 20.9597C3.06385 21.5458 3.8587 21.875 4.6875 21.875H20.3125C21.1413 21.875 21.9362 21.5458 22.5222 20.9597C23.1083 20.3737 23.4375 19.5788 23.4375 18.75V13.2812C23.4373 13.2271 23.4318 13.1731 23.4209 13.1201ZM4.6709 6.02051V6.00684C4.84424 5.10693 5.34522 4.68848 6.24756 4.68848H18.75C19.6587 4.68848 20.1597 5.10596 20.3257 6.00244C20.3257 6.00879 20.3281 6.01514 20.3291 6.02148L21.644 12.2656C21.65 12.2941 21.6495 12.3235 21.6427 12.3517C21.6358 12.38 21.6227 12.4063 21.6044 12.4289C21.5861 12.4514 21.5629 12.4696 21.5367 12.4821C21.5104 12.4946 21.4817 12.501 21.4526 12.501H15.625C15.419 12.4999 15.221 12.5807 15.0745 12.7255C14.9281 12.8704 14.845 13.0674 14.8438 13.2734C14.8438 13.895 14.5968 14.4912 14.1573 14.9307C13.7177 15.3703 13.1216 15.6172 12.5 15.6172C11.8784 15.6172 11.2823 15.3703 10.8427 14.9307C10.4032 14.4912 10.1563 13.895 10.1563 13.2734C10.1557 13.1714 10.1351 13.0704 10.0956 12.9763C10.0561 12.8821 9.99836 12.7967 9.92582 12.7249C9.85327 12.6531 9.76729 12.5963 9.67278 12.5577C9.57827 12.5191 9.47709 12.4995 9.375 12.5H3.54737C3.5183 12.5 3.48959 12.4936 3.46334 12.4811C3.43708 12.4686 3.41394 12.4505 3.3956 12.4279C3.37726 12.4054 3.36418 12.379 3.35733 12.3508C3.35047 12.3225 3.35 12.2931 3.35596 12.2646L4.6709 6.02051Z"
        fill={active ? '#1B2559' : '#FFFFFF'}
      />
      </svg>
    );
  }
  if (type === 'request') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'translateY(-2px)' }} width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M19.865 8.37596C19.673 8.04528 19.3974 7.77031 19.0655 7.57839C18.7336 7.38647 18.3569 7.28428 17.973 7.28197H15.3V5.09399C15.3 4.80385 15.1841 4.52559 14.9778 4.32042C14.7715 4.11526 14.4917 4 14.2 4H3.2C2.61652 4 2.05694 4.23052 1.64437 4.64084C1.23178 5.05117 1 5.60769 1 6.18798V17.1279C1.0004 17.5116 1.10224 17.8884 1.29531 18.2206C1.48838 18.5528 1.76589 18.8286 2.1 19.0205C2.06062 20.036 2.42847 21.0255 3.12263 21.7712C3.8168 22.517 4.78042 22.958 5.8015 22.9972C6.82258 23.0363 7.81749 22.6705 8.56736 21.9801C9.31722 21.2897 9.76062 20.3314 9.8 19.3159H13.21C13.3316 20.2312 13.7836 21.0714 14.4817 21.68C15.1799 22.2886 16.0766 22.6241 17.005 22.6241C17.9334 22.6241 18.8301 22.2886 19.5283 21.68C20.2264 21.0714 20.6784 20.2312 20.8 19.3159C21.3835 19.3159 21.9431 19.0854 22.3556 18.675C22.7682 18.2647 23 17.7082 23 17.1279V13.8459C22.9983 13.6463 22.9453 13.4505 22.846 13.277L19.865 8.37596ZM15.3 9.46995H17.973L19.953 12.7519H15.3V9.46995ZM5.95 20.4099C5.62366 20.4099 5.30465 20.3136 5.03331 20.1333C4.76197 19.953 4.55048 19.6967 4.4256 19.3969C4.30071 19.097 4.26804 18.7671 4.3317 18.4487C4.39537 18.1304 4.55252 17.838 4.78327 17.6085C5.01403 17.379 5.30803 17.2227 5.6281 17.1594C5.94817 17.0961 6.27993 17.1286 6.58143 17.2528C6.88293 17.377 7.14062 17.5873 7.32192 17.8572C7.50323 18.1271 7.6 18.4443 7.6 18.7689C7.6 19.2041 7.42616 19.6215 7.11673 19.9292C6.80729 20.237 6.38761 20.4099 5.95 20.4099ZM16.95 20.4099C16.6237 20.4099 16.3047 20.3136 16.0333 20.1333C15.762 19.953 15.5505 19.6967 15.4256 19.3969C15.3007 19.097 15.268 18.7671 15.3317 18.4487C15.3954 18.1304 15.5525 17.838 15.7833 17.6085C16.014 17.379 16.308 17.2227 16.6281 17.1594C16.9482 17.0961 17.2799 17.1286 17.5814 17.2528C17.8829 17.377 18.1406 17.5873 18.3219 17.8572C18.5032 18.1271 18.6 18.4443 18.6 18.7689C18.6 19.2041 18.4262 19.6215 18.1167 19.9292C17.8073 20.237 17.3876 20.4099 16.95 20.4099Z"
        fill={active ? '#1B2559' : '#FFFFFF'}
      />
      </svg>
    );
  }
  if (type === 'welcome') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
        <path
          d="M17 10V7H14V5H17V2H19V5H22V7H19V10H17ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.146 12.1127 0.438 11.638C0.73 11.1633 1.11733 10.8007 1.6 10.55C2.63333 10.0333 3.68333 9.646 4.75 9.388C5.81667 9.13 6.9 9.00067 8 9C9.1 9 10.1833 9.12933 11.25 9.388C12.3167 9.64667 13.3667 10.034 14.4 10.55C14.8833 10.8 15.271 11.1627 15.563 11.638C15.855 12.1133 16.0007 12.634 16 13.2V16H0Z"
          fill={active ? '#1B2559' : '#FFFFFF'}
        />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z"
      fill={active ? '#1B2559' : '#FFFFFF'}/>
    </svg>
  );
};

const WorksiteNav = () => {
  const { worksiteId } = useParams();
  const { pathname } = useLocation();
  return (
    <div className={styles.nav}>
      <nav>
        <NavLink to={`/worksite/${worksiteId}`}
          className={pathname === `/worksite/${worksiteId}` ? styles.active : ''}
        >
          <NavIcon
            type="infos"
            active={pathname === `/worksite/${worksiteId}`}
          />
          <span>Détails</span>
        </NavLink>
        <NavLink
          to={`/worksite/${worksiteId}/ged`}
          className={pathname?.includes('/ged') ? styles.active : ''}
        >
          <NavIcon
            type="ged"
            active={pathname?.includes('/ged')}
          />
          <span>GED</span>
        </NavLink>
        <NavLink
          to={`/worksite/${worksiteId}/request`}
          className={pathname?.includes('/request') ? styles.active : ''}
        >
          <NavIcon
            type="request"
            active={pathname?.includes('/request')}
          />
          <span>Demandes</span>
        </NavLink>
        <NavLink
          to={`/worksite/${worksiteId}/welcome`}
          className={pathname === `/worksite/${worksiteId}/welcome` ? styles.active : ''}
        >
          <NavIcon
            type="welcome"
            active={pathname === `/worksite/${worksiteId}/welcome`}
          />
          <span>fiche</span>
        </NavLink>
      </nav>
    </div>
  );
};

export default WorksiteNav;
