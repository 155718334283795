import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetWorksite } from '~/hooks/worksites';

import WorksiteHome from '~/components/WorksiteMember/WorksiteHome';
import GedCategory from '~/pages/worksite/gedCategory';
import WorksiteHeader from '~/components/worksite/WorksiteHeader';

import styles from './worksiteDesktop.module.scss';
import MemberRequestForm from '~/pages/MemberRequestForm';

const WorksiteMobile = () => {
  const { worksiteId } = useParams();
  const { data: worksite } = useGetWorksite(worksiteId || '');
  return (
    <div className={styles.worksiteDesktop}>
      <WorksiteHeader
        worksite={worksite}
      />
      <div className={styles.content}>
        <Routes>
          <Route path='/' element={<WorksiteHome />} />
          <Route path='/request/:type' element={<MemberRequestForm />} />
          <Route path='/:type' element={<GedCategory />} />
        </Routes>
      </div>
    </div>
  );
};

export default WorksiteMobile;
