import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createRequest, getRequestsList, getWorksiteRequests } from '~/api/requests';
import { IRequest } from '~/types/requests';

const useGetRequestsList = () => useQuery({
  queryKey: ['requests-list'],
  queryFn: async (): Promise<any | null> => getRequestsList(),
  keepPreviousData: true,
});

const useCreateRequest = (successMessage?: string) => {
  const queryRequest = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IRequest>) => createRequest(data),
    onSuccess: async ({ request }) => {
      console.log(request);
      queryRequest.invalidateQueries({
        queryKey: ['requests'],
        exact: false,
      });
      queryRequest.setQueryData(['request', request._id], request);
      if (successMessage) toast(successMessage);
    },
  });
};

const useGetWorksiteRequests = (id: string) => useQuery({
  queryKey: ['requests', id],
  queryFn: async (): Promise<any | null> => getWorksiteRequests(id),
  keepPreviousData: true,
});

export {
  useGetRequestsList,
  useCreateRequest,
  useGetWorksiteRequests,
};
