import React, { useCallback, useMemo, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IoCheckmark } from 'react-icons/io5';
import { IoIosSend } from 'react-icons/io';
import { LuUpload } from 'react-icons/lu';
import styles from './phase-sidebar.module.scss';
import { IPhase } from '~/types/estimates';
import { useGetEstimate } from '~/hooks/estimates';
import Modal from '~/lib/Modal';
import ModalExport from '../ModalExport';

type LinkId = {
  value: string,
  label: string,
  excludes?: string[]
};

export const phasesMenu = [
  {
    label: 'Dimension',
    value: '#dimension',
  },
  {
    label: 'Manutention',
    value: '#manutention',
  },
  {
    label: 'Montage/Démontage',
    value: '#montage',
  },
  {
    label: 'Location',
    value: '#location',
  },
  {
    label: 'Transport',
    value: '#transport',
  },
  {
    label: 'Chariot',
    value: '#cart',
  },
  {
    label: 'Vérification',
    value: '#verification',
  },
  {
    label: 'Filets',
    value: '#nets',
  },
  {
    label: 'Thermobachage',
    value: '#thermalTarping',
  },
  {
    label: 'Protection de Toit',
    value: '#roofProtection',
  },
  {
    label: 'Bardage',
    value: '#cladding',
  },
  {
    label: 'Fourniture Pose de Planches',
    value: '#board',
  },
  {
    label: 'Ascenseur',
    value: '#elevator',
  },
  {
    label: 'Frais d’études',
    value: '#study',
  },
  {
    label: 'Grue',
    value: '#crane',
  },
  {
    label: 'Résumé',
    value: '#summary',
  },
];

const PhaseSidebar = ({ isPreview }: { isPreview: boolean }) => {
  const location = useLocation();
  const { id, phaseId, ...params } = useParams();

  const exportRef = useRef<any>();
  const sendRef = useRef<any>();

  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase : any = useMemo(() => {
    const found = estimate?.phases?.find((d: IPhase) => d._id === phaseId || d._id === params['*']);
    return found;
  }, [estimate, phaseId, params]);

  const getPhaseValidation = useCallback((type: string) => {
    let isValid = false;
    if (type === '#dimension' && phase?.dimension?.tonnage) {
      isValid = true;
    }
    if (type === '#montage' && phase?.mobilization?.type) {
      isValid = true;
    }
    if (type === '#location' && phase?.rental?.duration) {
      isValid = true;
    }
    if (type === '#transport' && phase?.transport?.type && phase?.transport?.type !== 'Aucun') {
      isValid = true;
    }
    if (type === '#manutention' && phase?.handling?.type && phase?.handling?.type !== 'Aucun') {
      isValid = true;
    }
    if (type === '#cart' && phase?.cart.isCart) {
      isValid = true;
    }
    if (type === '#verification' && (phase?.verification.isReception || phase?.verification?.isQuarterly)) {
      isValid = true;
    }
    if (type === '#nets' && (phase?.nets)) {
      isValid = true;
    }
    if (type === '#thermalTarping' && phase?.thermalTarping?.surface) {
      isValid = true;
    }
    if (type === '#cladding' && phase?.cladding?.isCladding) {
      isValid = true;
    }
    if (type === '#board' && phase?.boards.countLaying && phase?.boards.width) {
      isValid = true;
    }
    if (type === '#elevator' && phase?.elevator?.duration && phase?.elevator?.rental) {
      isValid = true;
    }
    if (type === '#study' && phase?.study) {
      isValid = true;
    }
    if (type === '#crane' && (phase?.crane?.go?.unitPrice || phase?.crane?.back?.unitPrice)) {
      isValid = true;
    }
    return isValid;
  }, [estimate, phase]);

  return (
    <>
      <div className={styles.sidebar}>
        {estimate?._id
          && <div className={styles.actions}>
            {!isPreview
              && <button
                onClick={() => sendRef.current.open()}
              >
                <IoIosSend />Envoyer
              </button>
            }
            <button
              onClick={() => exportRef.current.open()}
            >
              <LuUpload />Exporter
            </button>
          </div>
        }
        <hr />
        <ul>
          {phasesMenu.filter((p:LinkId) => {
            if (phase.type !== 'umbrella' && p.value === '#crane') {
              return null;
            }
            if (phase.type === 'elevator') {
              if (p.value === '#elevator' || p.value === '#summary') {
                return p;
              }
              return null;
            }
            if (phase.type === 'umbrella') {
              if (p.value === '#board') {
                return null;
              }
              if (p.value === '#cladding') {
                return null;
              }
              if (p.value === '#thermalTarping') {
                return null;
              }
              if (p.value === '#nets') {
                return null;
              }
            }
            return p;
          }).map((link: LinkId) => <li
            key={link.value}
            className={location.hash === link.value ? styles.active : ''}
          >
            <Link to={`${link.value}`}>
              {getPhaseValidation(link.value) ? <IoCheckmark color="#6AA187" size={18} /> : null}
              <p>{link.label}</p>
            </Link>
          </li>)}
        </ul>
      </div>
      {estimate?._id
      && <>
        <Modal ref={exportRef} width={700}>
          <ModalExport
            estimate={estimate}
            close={() => exportRef?.current.close()}
          />
        </Modal>
        <Modal ref={sendRef} width={700}>
          <ModalExport
            title='Envoyer le devis'
            estimate={estimate}
            close={() => sendRef?.current.close()}
          />
        </Modal>
      </>
      }
    </>
  );
};

export default PhaseSidebar;
