import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Ged from '../../../pages/worksite/ged';
import GedCategory from '~/pages/worksite/gedCategory';
import { useGetWorksite } from '~/hooks/worksites';

import styles from './ged.module.scss';

const GedIndex = () => {
  const { worksiteId } = useParams();
  const { data: worksite } = useGetWorksite(worksiteId || '');
  return (
    <>
      <Routes>
        <Route path='/*' element={<div className={styles.ged}>
          <h1>{worksite?.estimate?.worksite?.name}</h1>
          <Ged />
        </div>}/>
        <Route path='/:type' element={<GedCategory />}/>
      </Routes>
    </>
  );
};

export default GedIndex;
