import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetEstimate, useGetEstimatesList } from '~/hooks/estimates';
import styles from './transport-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import { IOption } from '~/types/options';
import PreviewField from '~/components/PreviewField';
import { IPhase } from '~/types/estimates';

const TransportForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();

  const type = watch('transport.type');
  const { data: list = {} } = useGetEstimatesList();
  const { id, phaseId } = useParams();
  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase : any = useMemo(() => {
    const found = estimate?.phases.find((d: IPhase) => d._id === phaseId);
    return found;
  }, [estimate, phaseId]);

  const isUmbrella = phase?.type === 'umbrella';

  return (
    <div id="transport" className={styles.transport}>
      <h4>Transport</h4>
      {!isUmbrella
        ? <>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Type de trajets'
                value={list?.transport?.transportTypes?.find((o:IOption) => o.value === phase?.transport?.type)?.label || '---'}
              />
            : <StyledSelect
              label='Type de trajets'
              name='transport.type'
              control={control}
              options={list?.transport?.transportTypes}
              handleChange={(opt) => {
                if (opt.value !== 'Mode manuel') {
                  setValue('transport.custom.go', null);
                  setValue('transport.custom.back', null);
                }
              }}
            />
            }
          </div>
        </div>
        {type === 'Mode manuel'
        && <>
            <h5>Transport 6m</h5>
            <div className={styles.row}>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Aller'
                value={phase?.transport?.custom?.truck6m?.go || '---'}
              />
              : <InputNumber
                name='transport.custom.truck6m.go'
                label='Aller'
                control={control}
              />
            }
            </div>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Retour'
                value={phase?.transport?.custom?.truck6m?.back || '---'}
              />
              : <InputNumber
                name='transport.custom.truck6m.back'
                label='Retour'
                control={control}
              />
            }
            </div>
          </div>
          <h5>Transport 12m</h5>
            <div className={styles.row}>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                  label='Aller'
                  value={phase?.transport?.custom?.truck12m?.back || '---'}
                />
                : <InputNumber
                  name='transport.custom.truck12m.go'
                  label='Aller'
                  control={control}
                />
              }
            </div>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Retour'
                  value={phase?.transport?.custom?.truck12m?.back || '---'}
                />
              : <InputNumber
                name='transport.custom.truck12m.back'
                label='Retour'
                control={control}
              />
            }
            </div>
          </div>
        </>
        }
        {(type !== 'Mode manuel' && phase?.transport?.count)
          && <div className={styles.row}>
            <div>
            {(phase.transport.count.go.truck12m > 0 || phase.transport.count.go.truck6m > 0)
              && <>
                <div className={styles.count}>
                    <h5>Aller</h5>
                    {phase.transport.count.go.truck12m > 0
                      && <p>12m <span>{phase.transport.count.go.truck12m}</span></p>}
                    {phase.transport.count.go.truck6m > 0
                      && <p>6m <span>{phase.transport.count.go.truck6m}</span></p>}
                    {phase.transport.count.go.remaining
                      && <p>Tonnage restant <span>
                        {phase.transport.count.go.remaining?.toFixed(2)}
                      </span></p>}
                </div>
              </>
              }
              {(phase.transport.count.back.truck12m > 0 || phase.transport.count.back.truck6m > 0)
              && <>
                <div className={styles.count}>
                    <h5>Retour</h5>
                    {phase.transport.count.back.truck12m > 0
                      && <p>12m <span>{phase.transport.count.back.truck12m}</span></p>}
                    {phase.transport.count.back.truck6m > 0
                      && <p>6m <span>{phase.transport.count.back.truck6m}</span></p>}
                    {phase.transport.count.back.remaining
                      && <p>Tonnage restant <span>
                        {phase.transport.count.back?.remaining?.toFixed(2)}
                        </span>
                      </p>}
                </div>
              </>
              }
            </div>
          </div>
        }
        </>
        : <>
          <div className={styles.row}>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Nombre de transport 12m'
                value={phase?.transport?.umbrella?.truck12m || '---'}
              />
              : <InputNumber
                name='transport.umbrella.truck12m'
                label='Nombre de transport 12m'
                control={control}
              />
            }
            </div>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Nombre de transport 6m'
                value={phase?.transport?.umbrella?.truck6m || '---'}
              />
              : <InputNumber
                name='transport.umbrella.truck6m'
                label='Nombre de transport 6m'
                control={control}
              />
            }
            </div>

          </div>
        </>
      }
    </div>
  );
};

export default TransportForm;
