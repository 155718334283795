import React, { useEffect, useCallback } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { IoTrash } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useDeleteEstimatePhase, useUpdateEstimatePhase } from '~/hooks/estimates';
import styles from './phase-form.module.scss';

import {
  InputText,
} from '~/lib/HooksFormFields';
import { IPhase } from '~/types/estimates';

import DimensionForm from './DimensionForm';
import MobilizationForm from './MobilizationForm';
import RentalForm from './RentalForm';
import TransportForm from './TransportForm';
import HandlingForm from './HandlingForm';
import CartForm from './CartForm';
import VerificationForm from './VerificationForm';
import NetsForm from './NetsForm';
import ThermalTarpingForm from './ThermalTarpingForm';
import RoofProtectionForm from './RoofProtectionForm';
import CladdingForm from './CladdingForm';
import BoardsForm from './BoardsForm';
import ElevatorForm from './ElevatorForm';
import StudyForm from './StudyForm';
import withModalConfirm from '~/lib/withModalConfirm';
import Summary from '~/components/Summary';
import CraneForm from './CraneForm';

const PhaseForm = ({
  estimateId,
  phase,
  isPreview,
  confirm,
} : {
  estimateId: string,
  phase: IPhase,
  isPreview: boolean,
  confirm: (message: string, action?: () => void, cancel?: () => void) => void
}) => {
  const navigate = useNavigate();
  const {
    mutate: updateEstimatePhase,
  } = useUpdateEstimatePhase('Les modifications ont été prise en compte');

  const {
    mutate: deleteEstimatePhase,
  } = useDeleteEstimatePhase();

  const handleDelete = () => {
    if (isPreview) return;
    confirm(
      'Êtes-vous sûr de vouloir supprimer cette phase?',
      async () => {
        await deleteEstimatePhase({
          estimateId,
          phaseId: phase._id,
        });
        navigate(`/estimates/${estimateId}`);
        const layout = document?.getElementById('layout');
        if (layout) {
          layout.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      },
      () => null,
    );
  };

  const methods = useForm({
    defaultValues: {
      ...phase,
    },
  });

  const submit = useCallback(async (data: FieldValues) => {
    if (isPreview) return;
    updateEstimatePhase({
      estimateId,
      _id: phase._id,
      ...data,
    });
  }, [phase]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => methods.watch(() => {
    methods.handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <FormProvider {...methods}>
      <div className={styles.form}>
        <div id="title" className={`${styles.containerField} ${styles.name}`}>
          {isPreview
            ? <h1>{phase.name}</h1>
            : <InputText
            name='name'
            control={methods.control}
          />
          }
        </div>
        <div className={styles.containerField}>
        {isPreview
          ? <p>{phase.description}</p>
          : <InputText
            name='description'
            label='Description'
            placeholder='Description de la phase...'
            control={methods.control}
          />
        }
        </div>
        <section>
          {phase.type !== 'elevator'
            ? <>
              <DimensionForm isPreview={isPreview} />
              <hr />
              <HandlingForm isPreview={isPreview} />
              <hr />
              <MobilizationForm isPreview={isPreview} />
              <hr />
              <RentalForm isPreview={isPreview} />
              <hr />
              <TransportForm isPreview={isPreview} />
              <hr />
              <CartForm isPreview={isPreview} />
              <hr />
              <VerificationForm isPreview={isPreview} />
              <hr />
              {phase.type === ''
              && <>
              <NetsForm isPreview={isPreview} />
              <hr />
              <ThermalTarpingForm isPreview={isPreview} />
              <hr />
              </>
              }
              <RoofProtectionForm isPreview={isPreview} />
              <hr/>
              {phase.type === ''
              && <>
                <CladdingForm isPreview={isPreview} />
                <hr />
                <BoardsForm isPreview={isPreview} />
                <hr />
                <ElevatorForm isPreview={isPreview}/>
                <hr />
                </>
              }
              <StudyForm isPreview={isPreview} />
              {phase.type === 'umbrella'
              && <>
                <hr />
                <CraneForm isPreview={isPreview} />
              </>
              }
            </>
            : <>
          <ElevatorForm isPreview={isPreview}/>
          </>
        }
        </section>
        {phase.summary
          && <section>
            <Summary summary={phase.summary} />
        </section>
        }

        {!isPreview
          && <button
            type="button"
            className={styles.delete}
            onClick={() => handleDelete()}
          >
            <IoTrash /> Supprimer la phase
          </button>
        }
      </div>
    </FormProvider>
  );
};

export default withModalConfirm(PhaseForm);
