import React, { useMemo } from 'react';
import {
  Route, Routes,
  useParams,
} from 'react-router-dom';
import Infos from '~/components/WorksiteMember/Infos';
import WorksiteNav from '~/components/WorksiteNav';
import Ged from '~/components/WorksiteMember/GedIndex';
import MemberRequests from '~/pages/MemberRequests';
import MemberRequestForm from '~/pages/MemberRequestForm';

const WorksiteMobile = () => {
  const params = useParams();
  const navIsDisabled = useMemo(() => {
    console.log(params);
    if (params['*'] === 'request/transport') return true;
    if (params['*'] === 'request/material') return true;
    if (params['*'] === 'request/welcome') return true;
    if (params['*'] === 'welcome/welcome') return true;
    return false;
  }, [params]);
  return (
    <>
      <Routes>
        <Route path='/' element={<Infos />} />
        <Route path='/ged/*' element={<Ged />} />
        <Route path='/request/*' element={<MemberRequests />} />
        <Route path='/request/:type' element={<MemberRequestForm />} />
        <Route path='/welcome/*' element={<MemberRequests isWelcome />} />
        <Route path='/welcome/:type' element={<MemberRequestForm />} />
      </Routes>
      {!navIsDisabled && <WorksiteNav />}
    </>
  );
};

export default WorksiteMobile;
