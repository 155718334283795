import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styles from './pagination.module.scss';

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
}:{
  totalItems: number,
  itemsPerPage: number,
  currentPage: number,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const updateUrl = (page: number) => {
    const searchParams = new URLSearchParams(location.search);
    const skip = (page - 1) * itemsPerPage; // Calcul du skip
    searchParams.set('skip', skip.toString());
    setSearchParams(new URLSearchParams(searchParams));
  };

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      updateUrl(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pageNumbers.push(
        <button
          key={i}
          className={`${i === currentPage ? styles.active : ''}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  return (
    <div className={styles.pagination}>
      {renderPageNumbers()}
    </div>
  );
};

export default Pagination;
