import React from 'react';
import { useWindowSize } from 'usehooks-ts';
import WorksiteMobile from './WorksiteMobile';
import WorksiteDesktop from './WorksiteDesktop';

const MemberWorksite = () => {
  const { width } = useWindowSize();
  return (
    <>
      {width > 1024 ? <WorksiteDesktop /> : <WorksiteMobile />}
    </>
  );
};

export default MemberWorksite;
