import React, { useEffect, useMemo, useState } from 'react';
import StickyBox from 'react-sticky-box';
import { useForm } from 'react-hook-form';
import {
  Routes, Route, useLocation,
  useParams,
  useNavigate,
  NavLink,
} from 'react-router-dom';
import { format } from 'date-fns';
import { CiCircleCheck } from 'react-icons/ci';
import { fr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import ColorScale from 'color-scales';
import { BiPlus } from 'react-icons/bi';
import styles from './estimate.module.scss';
import {
  useGetEstimate, useCreateEstimatePhase,
  useGetEstimatesList,
  useCreateEstimateVarious,
  useUpdateEstimate,
  useDuplicateEstimatePhase,
} from '~/hooks/estimates';

import Loader from '~/components/Loader';
import GeneralSidebar from '~/components/estimate/GeneralSidebar';
import PhaseSidebar from '~/components/estimate/PhaseSidebar';

import GeneralInfos from './general-informations';
import Phase from './phase';
import BackButton from '~/components/BackButton';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import { IPhase, IVarious } from '~/types/estimates';
import Various from './various';
import { useUserProfile } from '~/hooks/auth';
import { IOption } from '~/types/options';

// devis/65defe5c41dbeb16e77cde00/ -> info général
// devis/65defe5c41dbeb16e77cde00/O/dimension

const colorScale = new ColorScale(0, 100, ['#EAEDFA', '#acaeb6']);

const Estimate = () => {
  const { id, phaseId, ...params } = useParams();
  const location = useLocation();
  const [addStep, setAddStep] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: userAuth } = useUserProfile();

  const {
    isLoading,
    data: estimate,
  } = useGetEstimate(id || '');

  const currentPhase = useMemo(() => {
    const found = estimate?.phases.find((d: IPhase) => d._id === params['*']);
    return found;
  }, [estimate, params]);

  const {
    mutateAsync: createEstimatePhase,
  } = useCreateEstimatePhase();

  const {
    mutateAsync: createEstimateVarious,
  } = useCreateEstimateVarious();

  const {
    mutateAsync: duplicateEstimatePhase,
  } = useDuplicateEstimatePhase();

  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      status: estimate?.status,
      worksite: {
        materialUsed: 'Multidirectionnel',
      },
    },
  });

  const { data: list = {} } = useGetEstimatesList();
  const { status } = list;
  const {
    mutate: updateEstimate,
  } = useUpdateEstimate('Les modifications ont été prise en compte');

  const isPreview = estimate?.status === 'validated'
    || estimate?.status === 'sent'
    || estimate?.status === 'refused'
    || (estimate?.status === 'review-pending' && estimate.author._id === userAuth?._id);

  useEffect(() => {
    if (!getValues('status') && estimate?.status) {
      setValue('status', estimate.status);
    }
  }, [estimate]);

  const generalInfosActive = useMemo(() => (
    location.pathname.split('/').length < 4
    || location.pathname.includes('/various')
  ), [location.pathname]);

  const handleCreatePhase = async (type: string = '') => {
    if (!id) return;
    try {
      const { estimate: updatedEstimate } = await createEstimatePhase({ estimateId: id, type });
      const phases: IPhase[] = updatedEstimate?.phases || [];
      navigate(`/estimates/${id}/${phases[phases.length - 1]._id}`);
      const layout = document?.getElementById('layout');
      if (layout) {
        layout.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } catch {
      toast.error('La phase n\'a pu être créé');
    }
  };

  const handleDuplicatePhase = async () => {
    if (!id || !currentPhase?._id) return;
    try {
      const { estimate: updatedEstimate } = await duplicateEstimatePhase({
        estimateId: id,
        phaseId: currentPhase?._id,
      });
      const phases: IPhase[] = updatedEstimate?.phases || [];
      navigate(`/estimates/${id}/${phases[phases.length - 1]._id}`);
      const layout = document?.getElementById('layout');
      if (layout) {
        layout.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } catch {
      toast.error('La phase n\'a pu être créé');
    }
  };

  const handleCreateVarious = async () => {
    if (!id) return;
    try {
      const { estimate: updatedEstimate } = await createEstimateVarious(id);
      const various: IVarious[] = updatedEstimate?.various || [];
      navigate(`/estimates/${id}/various/${various[various.length - 1]._id}`);
    } catch {
      toast.error('la phase divers n\'a pu être créé');
    }
  };

  const getIsActive = (pathname: string) => {
    let active = false;
    if (location.pathname === `/estimates/${id}${pathname}`) active = true;
    return active;
  };

  useEffect(() => {
    if (estimate?._id) {
      reset(estimate);
    }
  }, [estimate]);

  return (
    <div className={styles.estimate}>
      {isLoading && (
        <div className={styles.loader}><Loader /></div>
      )}
      {estimate && (
        <div className={styles.content}>
          <StickyBox>
            <div className={styles.sidebar}>
              <div className={styles.back}>
                <BackButton path='/estimates' />
                <NavLink to='/estimates'>Retour à la liste des devis</NavLink>
              </div>
              <div className={styles.updatedAt}>
                <CiCircleCheck size={20} />
                <p>Dernière modification {format(estimate.updatedAt, "dd.MM.yyyy à HH'h'mm", { locale: fr })}</p>
              </div>
              <div className={styles.status}>
                <label>Statut</label>
                <StyledSelect
                  name='status'
                  control={control}
                  options={watch('status') === 'sent' ? status?.filter((s:IOption) => s.value === 'sent' || s.value === 'refused' || s.value === 'validated') : status}
                  isDisabled={watch('status') !== 'sent'}
                  handleChange={(opt:any) => {
                    updateEstimate({ _id: estimate?._id, status: opt.value });
                  }}
                  withColorDot
                />
              </div>
              <hr />
              {generalInfosActive
                ? <GeneralSidebar
                  estimate={estimate}
                  isPreview={isPreview}
                />
                : <PhaseSidebar
                  isPreview={isPreview}
                />
              }
            </div>
          </StickyBox>
          <div className={styles.containers}>
            <div className={styles.navTabs}>
              <NavLink
                to=''
                style={{
                  position: 'relative',
                  zIndex: (estimate?.phases?.length || 0) + 101,
                  backgroundColor: getIsActive('') ? 'white' : colorScale.getColor(0).toRGBString(),
                }}
              >
                Informations
              </NavLink>
              {(estimate?.phases || []).map((phase: any, index: number) => (
                <NavLink
                  to={phase._id}
                  key={`phase-${phase._id}`}
                  style={{
                    position: 'relative',
                    zIndex: estimate.phases.length - index + 100,
                    boxShadow: getIsActive(`/${phase._id}`) ? 'none' : '0px 18px 32px rgba(208, 210, 218, 0.25)',
                    backgroundColor: getIsActive(`/${phase._id}`) ? 'white' : colorScale.getColor(
                      (100 / (estimate.phases.length + estimate.various.length)) * (index + 1),
                    ).toRGBString(),
                  }}
                >
                  {phase.name || `Phase ${index + 1}`}
                </NavLink>
              ))}
              {(estimate?.various || []).map((various: any, index: number) => (
                <NavLink
                  to={`various/${various._id}`}
                  key={`phase-${various._id}`}
                  style={{
                    position: 'relative',
                    zIndex: (estimate.various.length + estimate.phases.length) - index,
                    boxShadow: getIsActive(`/various/${various._id}`) ? 'none' : '0px 18px 32px rgba(208, 210, 218, 0.25)',
                    backgroundColor: getIsActive(`/various/${various._id}`) ? 'white' : colorScale.getColor(
                      (100 / (estimate.phases.length + estimate.various.length))
                      * (estimate.phases.length + index + 1),
                    ).toRGBString(),
                  }}
                >
                  {various.name || `Phase ${index + 1}`}
                </NavLink>
              ))}
              {!isPreview
                && estimate?.worksite?.name
                && estimate?.worksite?.city
                && estimate?.worksite?.department
                && estimate?.logistics?.operationalBase
              && <>
                {!addStep
                  ? <a className={styles.add} onMouseOver={() => setAddStep(true)}>
                    <BiPlus /> Ajouter une phase
                  </a>
                  : <div
                    className={styles['add-step']}
                    onMouseLeave={() => setAddStep(false)}
                  >
                    <div className={styles.select}>
                      <div className={styles.label}>
                      <BiPlus /> Ajouter une phase
                    </div>
                    <div className={styles.option} onClick={() => handleCreatePhase()}>
                      Phase N° {estimate.phases.length + 1}
                    </div>
                    <div className={styles.option} onClick={() => handleCreatePhase('elevator')}>
                      Phase ascenseur
                    </div>
                    <div className={styles.option} onClick={() => handleCreatePhase('umbrella')}>
                      Phase parapluie
                    </div>

                    <div className={styles.option} onClick={() => handleCreateVarious()}>
                      Phase divers
                    </div>
                    {currentPhase?._id
                    && <div className={styles.option} onClick={() => handleDuplicatePhase()}>
                      Dupliquer cette phase
                    </div>
                    }
                  </div>
                </div>}
                </>
              }
            </div>
            <div className={styles.tabs}>
              <Routes>
                <Route path='/' element={<GeneralInfos isPreview={isPreview} />} />
                <Route path='/various/:variousId' element={<Various isPreview={isPreview} />} />
                <Route path='/:phaseId' element={<Phase isPreview={isPreview} />} />
              </Routes>
            </div>
            {!isPreview
                && estimate?.worksite?.name
                && estimate?.worksite?.city
                && estimate?.worksite?.department
                && estimate?.logistics?.operationalBase
                && <div className={styles.addPhase} onClick={() => handleCreatePhase()}>
              <BiPlus />
              <span>Ajouter une phase</span>
            </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Estimate;
