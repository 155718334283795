import React, { useEffect } from 'react';
import {
  useNavigate,
  Routes, Route,
} from 'react-router-dom';
// import Layout from './components/Layout';
import { useWindowSize } from 'usehooks-ts';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import { useUserProfile } from './hooks/auth';
import Account from './pages/account';
import Home from './pages/home';
import Users from './pages/users';
import Clients from './pages/clients';
import Agencies from './pages/agencies';
import Agency from './pages/agency';
import Estimates from './pages/estimates';
import Estimate from './pages/estimate';
import Worksites from './pages/worksites';
import Worksite from './pages/worksite';
import LayoutAdmin from './components/LayoutAdmin';
import LayoutMember from './components/LayoutMember';
import MemberHome from './pages/MemberHome';
import MemberWorksite from './pages/MemberWorksite';
import MemberAccount from './pages/MemberAccount';

const Redirect = () => {
  const navigate = useNavigate();
  const { data: user } = useUserProfile();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!user && !token) {
      navigate('/login');
    }
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <p>Loading...</p>
    </div>
  );
};

const Mobile = () => (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    }}
    >
      <p>L&apos;application n&apos;est pas disponible<br/>
      sur cet appareil</p>
    </div>
);

const AppRoutes = () => {
  const { data: user } = useUserProfile();
  const { width } = useWindowSize();

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {user && user?.role !== 'Member' && (
        <Route path='*' element={
          width > 1024
            ? <LayoutAdmin key='app'>
              <Routes>
                <Route path='*' element={<Home />} />
                {user?.role === 'Admin' && (
                  <>
                    <Route path='/agencies' element={<Agencies />} />
                    <Route path='/agencies/:id' element={<Agency />} />
                    <Route path='/agencies/:id/*' element={<Agency />} />
                    <Route path='/users/*' element={<Users />} />
                  </>
                )}
                <Route path='/clients/*' element={<Clients />} />
                <Route path='/worksites' element={<Worksites />} />
                <Route path='/worksites/:id/*' element={<Worksite />} />
                <Route path='/estimates' element={<Estimates />} />
                <Route path='/estimates/:id/*' element={<Estimate />} />
                <Route path='/account' element={<Account />} />
              </Routes>
            </LayoutAdmin>
            : <Mobile />

        }/>
      )}
      {user && user?.role === 'Member' && (
        <Route path='*' element={
          <LayoutMember key='app'>
            <Routes>
              <Route path='*' element={<MemberHome/>} />
              <Route path='/worksite/:worksiteId/*' element={<MemberWorksite />} />
              <Route path='/account' element={<MemberAccount />} />
            </Routes>
          </LayoutMember>
        }/>
      )}
      <Route path='*' element={<Redirect />} />
    </Routes>
  );
};

export default AppRoutes;
