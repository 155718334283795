import React, { useMemo, useState } from 'react';
import 'react-day-picker/style.css';
import { fr } from 'date-fns/locale';
import { BsCheck } from 'react-icons/bs';
import { DayPicker } from 'react-day-picker';
import styles from './todos.module.scss';
import { TodoItem } from '~/types/worksites';
import { useUpdateWorksite } from '~/hooks/worksites';

const BellIcon = ({ color = '#ccd1de' }: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
  <path d="M20 17V18H2.00002V17L4.00002 15V9C4.00002 5.9 6.03002 3.17 9.00002 2.29V2C9.00002 1.46957 9.21073 0.960859 9.58581 0.585786C9.96088 0.210714 10.4696 0 11 0C11.5305 0 12.0392 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V2.29C15.97 3.17 18 5.9 18 9V15L20 17ZM13 19C13 19.5304 12.7893 20.0391 12.4142 20.4142C12.0392 20.7893 11.5305 21 11 21C10.4696 21 9.96088 20.7893 9.58581 20.4142C9.21073 20.0391 9.00002 19.5304 9.00002 19M18.75 1.19L17.33 2.61C18.1758 3.44542 18.8473 4.44042 19.3056 5.53732C19.764 6.63422 20 7.8112 20 9H22C22 6.07 20.84 3.25 18.75 1.19ZM1.89962e-05 9H2.00002C2.00002 6.6 2.96002 4.3 4.67002 2.61L3.25002 1.19C2.2179 2.21157 1.39909 3.42807 0.841161 4.76881C0.283234 6.10955 -0.00268464 7.54781 1.89962e-05 9Z" fill={color}/>
  </svg>
);

const Todo = ({
  todo,
  todos,
  handleChangeWorksite,
}:{
  todo: TodoItem,
  todos: TodoItem[]
  handleChangeWorksite: (obj: any) => void
}) => {
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const isLate = todo?.date && new Date(todo.date) <= new Date();

  const getColor = useMemo(() => {
    if (todo?.date && isLate) {
      return '#FC8E8F';
    }
    if (todo?.date && !isLate) {
      return '#6AA187';
    }
    return '#ccd1de';
  }, [todo, isLate]);

  async function handleChangeStatus() {
    const currentTodos = [...todos];
    const index : number = todos.findIndex((c: TodoItem) => c._id === todo._id);
    if (index !== -1) {
      currentTodos[index].isDone = !currentTodos[index].isDone;
      if (currentTodos[index].isDone) {
        currentTodos[index].date = null;
      }
    }

    await handleChangeWorksite({ todos: currentTodos });
  }

  async function handleChangeDate(date: Date | null) {
    const currentTodos = [...todos];
    const index : number = todos.findIndex((c: TodoItem) => c._id === todo._id);
    if (index !== -1) {
      currentTodos[index].date = date;
    }
    await handleChangeWorksite({ todos: currentTodos });
    setDateIsOpen(false);
  }

  const formattedDate = (date: Date) => (new Intl.DateTimeFormat('fr', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(date));

  return (
    <div className={styles.todo}>
      <div
        className={styles.content}
      >
        <div
          className={`${styles.check} ${!todo.isDone && styles.active}`}
          onClick={() => handleChangeStatus()}
          >
          <BsCheck />
        </div>
        <p>
          {todo.text}
          {todo.date && !todo.isDone
            && <>
              <br/>
              {todo.date && <>Rappel  prévu le <span className={isLate ? styles.late : ''}>{formattedDate(new Date(todo.date))}</span></>}
            </>
          }
        </p>
      </div>
      {!todo.isDone
      && <button
        type='button'
        className={styles.bell}
        onClick={() => setDateIsOpen(true)}
      >
          <BellIcon color={getColor} />
      </button>
      }
      {dateIsOpen && <div className={styles['date-container']}
        onMouseLeave={() => setDateIsOpen(false)}
      >
        <div className={styles.date}>
          <DayPicker
            mode="single"
            locale={fr} // Set the locale here
            weekStartsOn={1}
            disabled={{ before: new Date() }}
            selected={todo.date ? new Date(todo.date) : new Date()}
            onSelect={(date: any) => handleChangeDate(date)}
          />
          <button
            type="button"
            className={styles.deleteDate}
            onClick={() => handleChangeDate(null)}
          >
            Supprimer le rappel
          </button>
        </div>
      </div>
      }
    </div>
  );
};

const defaultTodo = {
  isDone: false,
  text: '',
};

const Todos = ({
  todos,
  worksiteId,
}: {
  todos: TodoItem[]
  worksiteId: string,
}) => {
  const [todo, setTodo] = useState(defaultTodo);
  const {
    mutate: updateEstimate,
  } = useUpdateWorksite();

  async function handleChangeWorksite(values = {}) {
    const data : any = {
      ...values,
      _id: worksiteId,
    };
    await updateEstimate(data);
  }

  async function handleAddTodo() {
    await handleChangeWorksite({ todos: [...todos, todo] });
    setTodo(defaultTodo);
  }

  return (
    <div className={styles.todos}>
      <div className={styles.input}>
        <div
          className={`${styles.check}`}
          >
        </div>
        <input
          value={todo.text}
          placeholder='Ajouter une tâche'
          onChange={(e) => setTodo((t) => ({ ...t, text: e.target.value }))}
        />
        {todo.text
          && <button
            type="button"
            className={styles.add}
            onClick={() => handleAddTodo()}
          >
            Ajouter
          </button>
        }
      </div>
      {todos
        .sort((a: TodoItem, b: TodoItem) => Number(a.isDone) - Number(b.isDone))
        .map((t: TodoItem) => <Todo
        key={t._id}
        todo={t}
        todos={todos}
        handleChangeWorksite={handleChangeWorksite}
      />)}
    </div>
  );
};

export default Todos;
