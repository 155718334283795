import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetEstimate } from '~/hooks/estimates';
import styles from './crane-form.module.scss';

import {
  InputNumber,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IPhase } from '~/types/estimates';

const CraneForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control } = useFormContext();

  const { id, phaseId } = useParams();
  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase : any = useMemo(() => {
    const found = estimate?.phases.find((d: IPhase) => d._id === phaseId);
    return found;
  }, [estimate, phaseId]);

  return (
    <div id="crane" className={styles.crane}>
      <h4>Grue</h4>
      <h5>Montage</h5>
      <div className={styles.row}>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
            label='Prix journalier'
            value={phase?.crane.go.unitPrice || '---'}
          />
          : <InputNumber
            name='crane.go.unitPrice'
            label='Prix journalier'
            control={control}
          />
        }
        </div>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
            label='Nombre de jour'
            value={phase?.crane.go.days || '---'}
          />
          : <InputNumber
            name='crane.go.days'
            label='Nombre de jour'
            control={control}
          />
        }
        </div>
      </div>
      <h5>Démontage</h5>
      <div className={styles.row}>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
            label='Prix journalier'
            value={phase?.crane.back.unitPrice || '---'}
          />
          : <InputNumber
            name='crane.back.unitPrice'
            label='Prix journalier'
            control={control}
          />
        }
        </div>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
            label='Nombre de jour'
            value={phase?.crane.back.days || '---'}
          />
          : <InputNumber
            name='crane.back.days'
            label='Nombre de jour'
            control={control}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default CraneForm;
