import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetEstimate, useGetEstimatesList } from '~/hooks/estimates';
import styles from './rental-form.module.scss';

import {
  Checkbox,
  StyledSelect,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IPhase } from '~/types/estimates';

const RentalForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control } = useFormContext();
  const { id, phaseId } = useParams();
  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase = useMemo(() => {
    const found = estimate?.phases.find((d: IPhase) => d._id === phaseId);
    return found;
  }, [estimate, phaseId]);

  const { data: list = { rental: {} } } = useGetEstimatesList();
  const {
    durations,
  } = list.rental;

  const isUmbrella = phase?.type === 'umbrella';

  return (
    <div id="location" className={styles.rental}>
      <h4>Location</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Durée'
              value={phase?.rental?.duration ? `${phase.rental.duration} mois` : '---'}
            />
            : <StyledSelect
            label='Durée'
            name='rental.duration'
            control={control}
            options={durations}
          />
          }
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          {!isUmbrella
          && <Checkbox
            name='rental.globalPrice'
            label='Appliquer un tarif global'
            control={control}
            disabled={isPreview}
          />
          }
        </div>
      </div>
      {phase?.rental?.unitPrice && phase?.rental?.unitPrice > 0
        && <div className={styles.row}>
          <p className={styles.price}>Prix unitaire {phase.rental.globalPrice ? `(pour ${estimate?.worksite.duration} mois)` : ''} : <span>{phase?.rental?.unitPrice} €</span></p>
        </div>
      }
       {phase?.rental?.raisedUnitPrice && phase?.rental?.raisedUnitPrice > 0
        && <div className={styles.row}>
          <p className={styles.price}>Prix unitaire réhausse :<span>
            {phase?.rental?.raisedUnitPrice} €</span></p>
        </div>
      }
    </div>
  );
};

export default RentalForm;
