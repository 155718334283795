import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { LuLogOut } from 'react-icons/lu';
import logo from './images/logo.png';
import styles from './layout.module.scss';
import { useSignOut, useUserProfile } from '~/hooks/auth';
import { IUser } from '~/types/users';

const LayoutMember = ({ children }: { children: React.ReactNode }) => {
  const signOut = useSignOut();
  const { data } = useUserProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const user: Partial<IUser> = data || {};

  return (
  <div className={styles.layout}>
    <div className={styles.navBar}>
      <div className={styles.mobile}>
        {location.pathname === '/'
          ? <NavLink to="/">
            <h1>Lvtech montage</h1>
          </NavLink>
          : <a className={styles.back}
            onClick={() => navigate(-1)}
          >
            <FiArrowLeft />
            <p>Retour</p>
          </a>
        }
      </div>
      <NavLink to="/" className={styles.desktop}>
        <img src={logo} alt='logo' />
        <h1>Mes chantiers</h1>
      </NavLink>
      <div className={styles.user}>
        <NavLink to='/account' >
          <div className={styles.initial}>
            <span>
              {user.profile?.firstName[0]}
              {user.profile?.lastName[0]}
            </span>
          </div>
          <p>{user.profile?.firstName} {user.profile?.lastName}</p>
        </NavLink>
        <button
          className={styles.logout}
          onClick={signOut}
        >
          <LuLogOut size={26} />
        </button>
      </div>
    </div>
    <div className={styles.global}>
      {children}
    </div>
  </div>
  );
};

export default LayoutMember;
