import axios from 'axios';
import { IRequest } from '~/types/requests';

const getRequestsList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/requests/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const getWorksiteRequests = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/requests/worksite/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  console.log(data);
  return data?.requests;
};

const createRequest = async (data: Partial<IRequest>): Promise<{ request: IRequest }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/requests`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const exportRequest = async (id: string, filename: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/requests/export/${id}`,
    {
      headers: {
        Authorization: token,
      },
      responseType: 'blob',
    },
  );
  const fileURL = URL.createObjectURL(data);
  if (filename) {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename; // Set filename for download
    link.click();
  } else {
    window.open(fileURL, '_blank');
  }
  setTimeout(() => URL.revokeObjectURL(fileURL), 100);
  return data;
};

export {
  getRequestsList,
  createRequest,
  getWorksiteRequests,
  exportRequest,
};
