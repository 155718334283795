/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { LuLogOut } from 'react-icons/lu';
import { HiOutlineDownload } from 'react-icons/hi';
import { useGetCurrentWorksites } from '~/hooks/worksites';
import styles from './memberAccount.module.scss';
import { useSignOut, useUserProfile } from '~/hooks/auth';
import { IFile } from '~/types/files';
import { useGetUsersList } from '~/hooks/users';
import { IOption } from '~/types/options';

const MemberAccount = () => {
  const { data: user } = useUserProfile();
  const { data: list } = useGetUsersList();
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const navigate = useNavigate();
  const signOut = useSignOut();

  const documents = useMemo(() => {
    const files : IFile[] = [];
    if (!user?.documents) return;
    for (const [key, value] of Object.entries(user.documents)) {
      const typedValue = value as any;
      for (const v of typedValue) {
        files.push({
          ...v.file,
          name: list?.documents.find((d:IOption) => d.value === key)?.label || key,
        });
      }
    }
    return files;
  }, [user?.documents, list?.documents]);

  const handleDownload = (url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const urlObj = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = urlObj;
        link.download = fileName || 'downloaded-file';
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlObj);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  };

  return (
    <div className={styles.home}>
      <header>
        <div className={styles['header-content']}>
           <a
            onClick={() => navigate(-1)}
            >
              <FiArrowLeft />
            </a>
          <h1>{user?.profile?.firstName} {user?.profile?.lastName}</h1>
          <p className={styles.agency}>{user?.agencies.map((a) => a.name).join(' - ')}</p>
          <p>{user?.email}</p>
          <p>{user?.phone}</p>
        </div>
      </header>
      <div className={styles.content}>
        <h2>Mes documents</h2>
        <div className={styles.documents}>
          {documents?.map((d:IFile) => (
            <a
              download={d.fileName}
              key={d._id}
              onClick={() => handleDownload(
                `${process.env.REACT_APP_API_URL}/files/public/${d.path}?token=${token}`,
                d.fileName,
              )}
            >
                <h3>{d.fileName}</h3>
                <p>{d.name}</p>
                <HiOutlineDownload />
            </a>
          ))}
        </div>
      </div>
      <div className={styles.logout}>
        <button onClick={signOut}>
          Déconnexion <LuLogOut size={26} />
        </button>
      </div>
    </div>
  );
};

export default MemberAccount;
