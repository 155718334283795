import React, { useEffect, useCallback } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { IoTrash } from 'react-icons/io5';
import { useDeleteEstimateVarious, useUpdateEstimateVarious } from '~/hooks/estimates';
import styles from './various-form.module.scss';

import { IVarious } from '~/types/estimates';
import { InputText } from '~/lib/HooksFormFields';
import FloorProtectionForm from './FloorProtectionForm';
import WinchesForm from './WinchesForm';
import CustomsForm from './CustomsForm';
import withModalConfirm from '~/lib/withModalConfirm';
import Summary from '~/components/Summary';

const VariousForm = ({
  estimateId,
  various,
  isPreview,
  confirm,
} : {
  estimateId: string,
  various: IVarious,
  isPreview: boolean,
  confirm: (message: string, action?: () => void, cancel?: () => void) => void
}) => {
  const navigate = useNavigate();
  const {
    mutate: updateEstimateVarious,
  } = useUpdateEstimateVarious('Les modifications ont été prise en compte');

  const {
    mutate: deleteEstimateVarious,
  } = useDeleteEstimateVarious();

  const handleDelete = () => {
    confirm(
      'Êtes-vous sûr de vouloir supprimer cette phase?',
      async () => {
        await deleteEstimateVarious({
          estimateId,
          variousId: various._id,
        });
        navigate(`/estimates/${estimateId}`);
      },
      () => null,
    );
  };

  const methods = useForm({
    defaultValues: {
      ...various,
    },
  });

  const submit = useCallback(async (data: FieldValues) => {
    updateEstimateVarious({
      estimateId,
      _id: various._id,
      ...data,
    });
  }, [various]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  const submitOnChange = () => methods.watch(() => {
    methods.handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <FormProvider {...methods}>
      <div className={styles.form}>
        <div className={`${styles.containerField} ${styles.name}`}>
        {isPreview
          ? <h1>{various.name}</h1>
          : <InputText
            name='name'
            control={methods.control}
          />
        }
        </div>
        <section>
          <FloorProtectionForm isPreview={isPreview} />
          <hr/>
          <WinchesForm isPreview={isPreview} />
          <CustomsForm isPreview={isPreview} />
        </section>
        {!isPreview
          && <button
            type="button"
            className={styles.delete}
            onClick={() => handleDelete()}
          >
            <IoTrash /> Supprimer la phase
          </button>
        }
        {various?.summary
          && <Summary summary={various.summary} />
        }
      </div>
    </FormProvider>
  );
};

export default withModalConfirm(VariousForm);
