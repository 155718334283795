import React, {
  ForwardRefRenderFunction,
  useRef, forwardRef, useImperativeHandle,
  useMemo,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { LuPaperclip } from 'react-icons/lu';
import { MdDeleteOutline } from 'react-icons/md';

import {
  Textarea,
} from '~/lib/HooksFormFields';

import Modal from '~/lib/Modal';
import styles from './modal-note.module.scss';
import { INote } from '~/types/clients';

export type ModalNoteHandle = {
  open: () => void,
  close: () => void;
};

const ModalNote: ForwardRefRenderFunction<
ModalNoteHandle, {
  documentId: string,
  note?: INote,
  useAddNote?: any,
  useUpdateNote?: any,
  handleClose?: () => void,
}> = ({
  documentId,
  note,
  useAddNote,
  useUpdateNote,
  handleClose,
}, ref) => {
  const modalRef = useRef<any>(null);
  const inputRef = useRef<any>();

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      text: note?.text || '',
      file: note?.file || null,
    },
  });

  const watchFile = watch('file');

  const {
    mutateAsync: addNote = null,
  } = typeof useAddNote === 'function' ? useAddNote() : {};

  const {
    mutateAsync: updateNote = null,
  } = typeof useUpdateNote === 'function' ? useUpdateNote() : {};

  useImperativeHandle(ref, () => ({
    open: () => {
      modalRef.current.open();
      reset({
        text: note?.text || '',
        file: note?.file || null,
      });
    },
    close: () => {
      modalRef.current.close();
      reset({
        text: '',
        file: null,
      });
    },
  }), [note]);

  const submit = async (data: FieldValues) => {
    try {
      if (note && updateNote) {
        let file = getValues('file');

        if (!file?.size && file?._id) {
          file = file._id;
        } else if (!file?.size && !file?._id) {
          file = null;
        }

        const res = await updateNote({
          documentId,
          _id: note._id,
          ...data,
          file,
        });
        if (res.client) {
          modalRef.current.close();
          reset({
            text: '',
            file: null,
          });
        }
      } else if (addNote) {
        const res = await addNote({
          documentId,
          ...data,
        });
        if (res.client) {
          modalRef.current.close();
          reset({
            text: '',
            file: null,
          });
        }
      }
      if (typeof handleClose === 'function') handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const fileName = useMemo(() => (watchFile as any)?.name || '', [watchFile]);

  return (
    <Modal
      width={450}
      ref={modalRef}
      handleClose={() => {
        if (typeof handleClose === 'function') handleClose();
      }}
    >
        <div className={styles.modal}>
          <h3>Ajouter une note</h3>
          <label>
            Description
          </label>
          <div className={styles.containerField}>
            <Textarea
              name='text'
              control={control}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
          </div>
          {fileName ? (
            <div className={styles.file}>
              <p>{fileName}</p>
              <button className='invisible' onClick={() => setValue('file', null)}>
                <MdDeleteOutline size={22} />
              </button>
            </div>
          ) : (
            <>
              <div className={styles.inputFile} onClick={() => inputRef.current.click()}>
                <label>
                  Ajouter une pièce jointe
                </label>
                <button>
                  <LuPaperclip size={18} />
                </button>
              </div>
              <input
                ref={inputRef}
                style={{ visibility: 'hidden' }}
                type="file"
                id="avatar"
                name="note-picture"
                accept="image/png, image/jpeg, application/pdf"
                onChange={(e:any) => {
                  const [file] = e.target.files;
                  setValue('file', file || null);
                }}
              />
            </>
          )}
          <div className={styles.buttons}>
            <button
              className='invisible'
              onClick={() => {
                if (typeof handleClose === 'function') handleClose();
              }}
            >Annuler</button>
            <button onClick={handleSubmit(submit)}>Valider</button>
          </div>
        </div>
    </Modal>
  );
};

export default forwardRef(ModalNote);
