import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './nets-form.module.scss';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useGetEstimatesList } from '~/hooks/estimates';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';

const NetsForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const phase = watch();
  const { data: list = { nets: [] } } = useGetEstimatesList();
  return (
    <div id="nets" className={styles.nets}>
      <h4>Filets</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Filets'
              value={list?.nets?.find((a:IOption) => a.value === phase?.study)?.label || '---'}
            />
          : <StyledSelect
            label='Filets'
            name='nets'
            control={control}
            options={list?.nets || []}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default NetsForm;
