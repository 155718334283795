import React, { useRef } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './worksiteDesktop.module.scss';
import Ged from '~/pages/worksite/ged';
import Infos from '~/components/WorksiteMember/Infos';
import { useGetRequestsList, useGetWorksiteRequests } from '~/hooks/requests';
import { exportRequest } from '~/api/requests';
import { RequestIcon } from '~/pages/MemberRequests';
import { FormTemplate } from '~/types/requests';
import Modal from '~/lib/Modal';

const WorksiteHome = () => {
  const { worksiteId } = useParams();
  const { data: requests } = useGetWorksiteRequests(worksiteId || '');
  const { data: list } = useGetRequestsList();
  const navigate = useNavigate();
  const modalRef = useRef<any>();

  async function handleDownloadRequest(request: any) {
    await exportRequest(request._id, null);
  }

  return (
  <>
    <div className={styles.worksiteDesktop}>
      <div className={styles.content}>
        <div className={styles.col}>
          <h3>Informations générales</h3>
          <Infos
            isDesktop
          />
        </div>
        <div className={styles.col}>
          <h3>GED</h3>
          <Ged isDesktop />
        </div>
        <div className={styles.col}>
          <h3>Mes demandes</h3>
          <button
            className={styles.new}
            onClick={() => modalRef.current.open()}
          >
            <BiPlus />
            <span>Nouvelle demande</span>
          </button>
          <div className={styles.requests}>
            {list?.templates && requests?.filter((r:any) => r.type !== 'welcome').map((r: any) => <button
              key={r._id}
              onClick={() => handleDownloadRequest(r)}
            >
              <RequestIcon type={r.type} />
              <div className={styles.infos}>
                <p>{list.templates.find((t: FormTemplate) => t.type === r.type)?.label}</p>
                <p>{format(new Date(r.createdAt), 'dd.MM.yyyy')}</p>
              </div>
            </button>)}
          </div>
          <hr/>
          <h3>Fiche d’accueil</h3>
          <button
            className={styles.new}
            onClick={() => navigate(`/worksite/${worksiteId}/request/welcome`)}
          >
            <BiPlus />
            <span>Nouvelle fiche</span>
          </button>
          <div className={styles.requests}>
            {list?.templates && requests?.filter((r:any) => r.type === 'welcome').map((r: any) => <button
              key={r._id}
              onClick={() => handleDownloadRequest(r)}
            >
              <RequestIcon type={r.type} />
              <div className={styles.infos}>
                <p>{list.templates.find((t: FormTemplate) => t.type === r.type)?.label}</p>
                <p>{format(new Date(r.createdAt), 'dd.MM.yyyy')}</p>
              </div>
            </button>)}
          </div>
        </div>
      </div>
    </div>
    <Modal ref={modalRef} width={455}>
      <div className={styles.modal}>
        <h3>Type de demande</h3>
        <div className={styles.options}>
          {list?.templates.filter((r:any) => r.type !== 'welcome').map((t: any) => <Link
            key={t.type}
            to={`request/${t.type}`}
          >
            <RequestIcon type={t.type} />
            <p>{t.label}</p>
          </Link>)}
        </div>
      </div>
    </Modal>
  </>
  );
};

export default WorksiteHome;
