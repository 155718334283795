/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGetCurrentWorksites } from '~/hooks/worksites';
import styles from './memberHome.module.scss';
import { useUserProfile } from '~/hooks/auth';
import { IWorksite } from '~/types/worksites';
import { IFile } from '~/types/files';
import { useGetUsersList } from '~/hooks/users';
import { IOption } from '~/types/options';

const MemberHome = () => {
  const { data: worksites = [] } = useGetCurrentWorksites();
  const { data: user } = useUserProfile();
  const { data: list } = useGetUsersList();
  const token = localStorage.getItem('token')?.replace('JWT ', '');

  const documents = useMemo(() => {
    const files : IFile[] = [];
    if (!user?.documents) return;
    for (const [key, value] of Object.entries(user.documents)) {
      const typedValue = value as any;
      for (const v of typedValue) {
        files.push({
          ...v.file,
          name: list?.documents.find((d:IOption) => d.value === key)?.label || key,
        });
      }
    }
    return files;
  }, [user?.documents, list?.documents]);

  const handleDownload = (url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const urlObj = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = urlObj;
        link.download = fileName || 'downloaded-file';
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlObj);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  };

  return (
    <div className={styles.home}>
      <div className={styles.content}>
        <h1>Bonjour {user?.profile?.firstName}!</h1>
        <div className={styles.row}>
          <div className={styles.col}>
            <h2>Mes chantiers</h2>
            <div className={styles.worksites}>
              {worksites.map((w:IWorksite) => <Link
                key={w._id}
                to={`/worksite/${w._id}`}
              >
                <h3>{w.estimate.worksite.name}</h3>
                <p>{w.estimate.worksite.department} - {w.estimate.worksite.city}</p>
              </Link>)}
            </div>
          </div>
          <div className={styles.col}>
            <h2>Mes documents</h2>
            <div className={styles.documents}>
              {documents?.map((d:IFile) => (
                <a
                  download={d.fileName}
                  key={d._id}
                  onClick={() => handleDownload(
                    `${process.env.REACT_APP_API_URL}/files/public/${d.path}?token=${token}`,
                    d.fileName,
                  )}
                >
                    <h3>{d.fileName}</h3>
                    <p>{d.name}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberHome;
