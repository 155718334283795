import React, {
  ForwardRefRenderFunction, forwardRef, useImperativeHandle,
  useCallback, useEffect, useRef, useState,
} from 'react';
import { IoMdAdd } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { FieldValues, useForm } from 'react-hook-form';
import { IUser } from '~/types/users';
import Modal from '~/lib/Modal';
import { useUpdateUserDocuments } from '~/hooks/users';
import styles from './member-form.module.scss';
import {
  Textarea, DropInputFile, StyledSelect, ErrorField,
} from '~/lib/HooksFormFields';
import { IOption } from '~/types/options';

export type MemberFormHandle = {
  getFormDataDocuments: () => FormData;
};

type MemberFormProps = { list: any, user?: IUser | null };

const MemberForm: ForwardRefRenderFunction<
MemberFormHandle, MemberFormProps> = ({ list = {}, user }, ref) => {
  const modalRef = useRef<any>();
  const {
    mutate: updateUserDocuments,
  } = useUpdateUserDocuments();
  const [formDocuments, setFormDocuments] = useState<Record<string, any>>(user?.documents || {});
  const {
    control: controlDocument,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { documents = [] } = list;

  const getFormdataFromDocuments = (docs: Record<string, any>) => {
    const formData = new FormData();
    Object.entries(docs).forEach(([key, value]) => {
      const files = value;
      files.forEach((d: { file: any, description?: string }, index: number) => {
        if (d.file instanceof File) {
          formData.append(`${key}[${index}].file`, d.file);
          formData.append(`${key}[${index}].description`, d.description || '');
        }
      });
    });
    return formData;
  };

  useImperativeHandle(ref, () => ({
    getFormDataDocuments: () => getFormdataFromDocuments(formDocuments),
  }), [formDocuments]);

  useEffect(() => {
    setFormDocuments(user?.documents || {});
  }, [user?.documents]);

  const addDocument = (data: FieldValues) => {
    clearErrors();
    if (!data.file) {
      return setError('file', { message: 'Le ficher est requis' });
    }
    const updatedDocuments = {
      ...formDocuments,
    };
    if (Array.isArray(updatedDocuments[data.type])) {
      updatedDocuments[data.type].push({
        file: data.file,
        description: data.description,
      });
    } else {
      updatedDocuments[data.type] = [{
        file: data.file,
        description: data.description,
      }];
    }

    // const test: any = {};
    const formData = getFormdataFromDocuments(updatedDocuments);
    if (user) {
      updateUserDocuments({
        _id: user._id,
        documents: formData,
      });
    }

    setFormDocuments(updatedDocuments);
    modalRef.current.close();
    return reset();
  };

  const deleteDocument = useCallback((key: string) => {
    if (!user) return;
    const formData = new FormData();
    formData.append(`${key}.file`, '');
    formData.append(`${key}.description`, '');
    updateUserDocuments({
      _id: user._id,
      documents: formData,
    });
  }, [user]);

  const cancel = () => {
    reset();
    modalRef.current.close();
  };

  const renderDocuments = useCallback((key: string) => {
    const files = formDocuments[key] || [];
    return (
      <>
        {files.map((d: any, index: number) => (
          <div className={styles.file} key={`file-${index}`}>
            <div>
              <p>{d.description}</p>
              <p className={styles.fileName}>{d.file?.name || d.file.fileName}</p>
            </div>
            {d.file.fileName && (
              <span className={styles.delete} onClick={() => deleteDocument(`${key}[${index}]`)}>
                <MdDelete size={14}/>
              </span>
            )}
          </div>
        ))}
      </>
    );
  }, [formDocuments]);

  return (
    <div className={styles.memberForm}>
      <div className={styles.header}>
        <h4>Documents</h4>
        <button onClick={() => modalRef.current.open()}>
          <span className='icon'><IoMdAdd /></span>
          <span>Ajouter</span>
        </button>
      </div>
      <div className={styles.documents}>
          {documents.map((doc: IOption) => (
            <div className={styles.document} key={`doc-${doc.value}`}>
              <div>
                <label>{doc.label}</label>
              </div>
              <div className={styles.files}>
                {renderDocuments(doc.value as string)}
              </div>
            </div>
          ))
          }
      </div>
      <Modal ref={modalRef} width={450} handleClose={() => {
        reset();
      }}>
        <div className={styles.modal}>
          <h4>Ajouter un document</h4>
          <div className={styles.containerField}>
            <DropInputFile
              name='file'
              control={controlDocument}
            />
            {errors.file?.message && <ErrorField message={errors.file?.message} />}
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name='type'
              label='Type de document'
              options={documents}
              control={controlDocument}
            />
          </div>
          <div className={styles.containerField}>
            <Textarea
              label='Description (facultatif)'
              name='description'
              control={controlDocument}
              rows={5}
            />
          </div>
          <div className={styles.actions}>
            <button className='invisible' onClick={cancel}>Annuler</button>
            <button onClick={handleSubmit(addDocument)}>
              <span className='icon'><BsCheck size={22} /></span>
              <span>Valider</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default forwardRef(MemberForm);
