import React from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './Infos.module.scss';
import { useGetWorksite } from '~/hooks/worksites';

const Infos = ({ isDesktop = false }: { isDesktop?: boolean }) => {
  const { worksiteId } = useParams();
  const { data: worksite } = useGetWorksite(worksiteId || '');
  return (
    <div className={`${styles.infos} ${isDesktop ? styles.desktop : ''}`}>
      {!isDesktop
        && <h1>{worksite?.estimate?.worksite?.name}</h1>
      }
      <div className={styles.field}>
        <label>Contact</label>
        <p>{worksite?.estimate?.contact?.lastName} {worksite?.estimate?.contact?.firstName}</p>
      </div>
      <div className={styles.field}>
        <label>Téléphone</label>
        {worksite?.estimate?.contact?.phone
          ? <a href={`tel:${worksite?.estimate?.contact?.phone}`} rel="noreferrer">
          {worksite?.estimate?.contact?.phone}
        </a>
          : <p>---</p>
        }
      </div>
      {worksite?.estimate?.worksite.address
      && <div className={styles.field}>
        <label>Adresse</label>
        <a target="_blank" href={`https://www.google.com/search?q=${worksite?.estimate?.worksite.address.replace(' ', '+')}+${worksite?.estimate?.worksite.city}`} rel="noreferrer"
          >{worksite?.estimate?.worksite.address}<br/>
            {worksite?.estimate?.worksite.address2
              && <>
                {worksite?.estimate?.worksite.address2}<br/>
              </>
            }
          {worksite?.estimate?.worksite.city}</a>
      </div>
      }
      <div className={styles.field}>
        <label>Date démarrage prévisionnelle</label>
        <p>
          {worksite?.estimatedDates?.startDate ? format(new Date(worksite?.estimatedDates?.startDate), 'dd.MM.yyyy') : '-'}
        </p>
      </div>
    </div>
  );
};

export default Infos;
