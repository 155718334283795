import axios from 'axios';
import { INote } from '~/types/estimates';
import { ArgsGetWorksites, IWorksite, WorksiteComment } from '~/types/worksites';

const getWorksitesList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/worksites/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const getWorksites = async (params: ArgsGetWorksites) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/worksites`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

const getCurrentWorksites = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/worksites/current`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const createWorksite = async (data: Partial<IWorksite>): Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/worksites`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const getWorksite = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/worksites/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.worksite;
};

const updateWorksite = async (data: Partial<IWorksite>): Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/worksites/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const addWorksiteFile = async (data: Partial<any>)
: Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/worksites/file/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const deleteWorksiteFile = async ({
  fileId, worksiteId,
} : {
  fileId: string, worksiteId: string
}) : Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/worksites/file/${worksiteId}/${fileId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const addWorksiteNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const { documentId, ...updatedData } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/worksites/${documentId}/notes`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const updateWorksiteNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const { documentId, _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/worksites/${documentId}/notes/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const deleteWorksiteNote = async ({
  noteId, worksiteId,
} : {
  noteId: string, worksiteId: string
}) : Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/worksites/${worksiteId}/notes/${noteId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const addWorksiteComment = async (data: Partial<WorksiteComment> & { worksiteId: string })
: Promise<{ worksite: IWorksite }> => {
  const token = localStorage.getItem('token');
  const { worksiteId, ...updatedData } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/worksites/${worksiteId}/comments`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

export {
  getWorksitesList,
  getWorksite,
  getWorksites,
  getCurrentWorksites,
  createWorksite,
  updateWorksite,
  addWorksiteFile,
  deleteWorksiteFile,
  addWorksiteNote,
  updateWorksiteNote,
  deleteWorksiteNote,
  addWorksiteComment,
};
