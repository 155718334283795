import React from 'react';
import styles from './summary.module.scss';
import { SummaryType } from '~/types/estimates';

const Summary = ({ summary }:{ summary: SummaryType }) => (
    <div id="summary" className={styles.summary}>
      <hr />
      <h4>Résumé de la phase</h4>
      <div className={`${styles.row} ${styles.label}`}>
        <p>Désignation</p>
        <p>Quantité</p>
        <p>Pu net</p>
        <p>Total</p>
      </div>
      {summary.steps.filter((s) => s.total > 0).map((s) => <div
        key={s.label}
        className={styles.row}
      >
        <p>{s.label}</p>
        <p>{s.quantity.value} {s.quantity.unit}</p>
        <p>{s.unitPrice.value} {s.unitPrice.unit || '€'}</p>
        <p>{s.total} €</p>
      </div>)}

      <div className={styles.total}>
        <div className={styles.count}>
          <p>Total HT</p><p><span>{summary.totalHT} €</span></p>
        </div>
        <div className={styles.count}>
          <p>TVA applicable 20 %</p><p>{summary.tva} €</p>
        </div>
        <div className={styles.count}>
          <p>Total TTC</p><p><span>{summary.totalTTC} €</span></p>
        </div>
      </div>
      <hr />
    </div>
);

export default Summary;
