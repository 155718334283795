import React, { useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { BiSolidBellRing } from 'react-icons/bi';
import { useUserProfile } from '~/hooks/auth';
import { useGetStatsEstimates } from '~/hooks/estimates';
import Modal from '~/lib/Modal';
import CreateEstimate from '~/components/estimate/form/CreateEstimate';
import styles from './home.module.scss';
import Loader from '~/components/Loader';
import { daysDifference } from '~/utils';

const Callback = ({
  callback,
} : {
  callback: any,
}) => {
  const days = useMemo(() => daysDifference(new Date(callback.date)), [callback.date]);
  const getColor = useMemo(() => {
    if (callback.isActive && days <= 0) return '#FC8E8F';
    if (callback.isActive) return '#1B2559';
    return '#B8BDCB';
  }, [days, callback.isActive]);

  return (
    <Link
      key={callback._id}
      to={`/estimates/${callback.estimate}`}
      className={styles.callback}
    >
      <div>
        <h2>{callback.worksite}</h2>
        <p>{callback?.message.length > 20 ? `${callback?.message.slice(0, 20)}...` : callback?.message}</p>
      </div>
      <div className={styles.status}>
        <BiSolidBellRing fontSize={20} color={getColor}/>
       {callback.isActive && days === 0 && <p className={styles.days}>Aujourd&#39;hui</p>}
       {callback.isActive && days < 0 && <p className={styles.days}>
        {Math.ceil(Math.abs(days))}j de retard</p>}
       {callback.isActive
       && days > 0 && <p className={styles.days}>{Math.ceil(Math.abs(days))} Jours</p>}
       </div>
    </Link>
  );
};

const Home = () => {
  const modalRef = useRef<any>();
  const { data: user } = useUserProfile();
  const navigate = useNavigate();
  const {
    isLoading: isStatsLoading,
    data: statsEstimates,
  } = useGetStatsEstimates();

  return (
    <>
      <Modal width={450} ref={modalRef}>
        <CreateEstimate onSuccess={(estimateId: string) => {
          modalRef.current.close();
          navigate(`/estimates/${estimateId}`);
        }} />
      </Modal>
        <div className={styles.home}>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Bonjour {user ? user.profile.firstName : ''} !</h1>
              <button onClick={() => modalRef.current.open()}>
                <span className='icon'><IoMdAdd /></span>
                <span>Créer un devis</span>
              </button>
            </div>
            {isStatsLoading
              ? <div className={styles.loader}>
                <Loader />
              </div>
              : <div className={styles.stats}>
              <div>
                <h4>Statistiques</h4>
                <div className={styles.statsEstimates}>
                  {statsEstimates && (
                    <>
                      <div className={styles.block}>
                        <p>Nb de devis total</p>
                        <p className={styles.count}>{statsEstimates.estimatesCount}</p>
                      </div>
                      <div className={styles.block}>
                        <p>Nb de devis en cours</p>
                        <p className={styles.count}>{statsEstimates.estimatesInProgress}</p>
                      </div>
                      <div className={styles.block}>
                        <p>Nb de devis ce mois-ci</p>
                        <p className={styles.count}>{statsEstimates.estimatesThisMonth}</p>
                      </div>
                      <div className={styles.block}>
                        <p>Volume en euro</p>
                        <p className={styles.count}>{statsEstimates.estimatesVolume?.toLocaleString('fr')} €</p>
                      </div>
                      <div className={styles.block}>
                        <p>Taux de convertion</p>
                        <p className={styles.count}>{statsEstimates.estimatesConvertionRate} %</p>
                      </div>
                    </>
                  )}
                </div>

                {statsEstimates?.callbacksEstimates?.length > 0
                && <>
                  <hr />
                  <h4>Liste des rappels</h4>
                  <div className={styles.estimates}>
                    {statsEstimates?.callbacksEstimates?.map((e:any) => (
                      <Callback
                        key={e._id}
                        callback={e}
                      />
                    ))}
                  </div>
                  </>
                }
              </div>

              <div>
              {statsEstimates.estimatesSent.length > 0
                && <>
                <h4>Derniers devis envoyés</h4>
                <div className={styles.estimates}>
                  {statsEstimates.estimatesSent?.map((e:any) => (
                    <Link
                      key={e._id}
                      to={`/estimates/${e._id}`}
                      className={styles.estimate}
                    >
                      <h2>{e.name}</h2>
                      <p>{e.agency.location}</p>
                      <p>{e.city}</p>
                    </Link>
                  ))}
                </div>
                {statsEstimates.estimatesValid.length > 0 && <hr/>}
                </>
              }
                {statsEstimates.estimatesValid.length > 0
                && <>
                <h4>Derniers devis validés</h4>
                <div className={styles.estimates}>
                  {statsEstimates.estimatesValid?.map((e:any) => (
                    <Link
                      key={e._id}
                      to={`/estimates/${e._id}`}
                      className={styles.estimate}
                    >
                      <h2>{e.name}</h2>
                      <p>{e.agency.location}</p>
                      <p>{e.city}</p>
                    </Link>
                  ))}
                </div>
                </>
              }
              </div>
            </div>
            }
          </div>
        </div>
    </>
  );
};

export default Home;
