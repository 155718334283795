import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import styles from './formField.module.scss';
import {
  ErrorField, InputText, StyledSelect, Textarea,
} from '~/lib/HooksFormFields';
import { FormFieldType } from '~/types/requests';
import InputDate from '~/lib/HooksFormFields/InputDate';

const FormField = ({
  field,
  control,
  errors,
}:{
  field: FormFieldType
  control: Control
  errors: any
}) => {
  const name = field.name.replace('content.', '');
  const error = errors?.content && errors?.content[name]?.message;
  const { setValue, watch } = useFormContext();

  return (
    <div className={`${styles.field} ${field.col ? styles[field.col] : ''}`}>
      {field?.label && <label>{field?.label}</label>}
      {field.type === 'select' && field.options
        && <div className={styles.select}>
          <StyledSelect
            name={field.name}
            control={control}
            options={field.options}
            rules={field.required ? { required: 'Ce champs est requis' } : {}}
            className='request'
        />
        </div>
      }
      {field.type === 'date'
        && <div className={styles.date}>
          <InputDate
            name={field.name}
            control={control}
            rules={field.required ? { required: 'Ce champs est requis' } : {}}
            className='request'
        />
        </div>
      }
      {field.type === 'input'
        && <div className={styles.input}>
          <InputText
            name={field.name}
            control={control}
            rules={field.required ? { required: 'Ce champs est requis' } : {}}
            className='request'
        />
        </div>
      }
      {field.type === 'boolean'
        && <div className={styles.boolean}>
          <button
            className={watch(field.name) === true ? styles.isSelected : ''}
            onClick={() => setValue(field.name, true)}
          >
            OUI
          </button>
          <button
            className={watch(field.name) === false ? styles.isSelected : ''}
            onClick={() => setValue(field.name, false)}
          >
            NON
          </button>
        </div>
      }
      {field.type === 'textarea'
        && <div className={styles.date}>
          <Textarea
            name={field.name}
            control={control}
            rules={field.required ? { required: 'Ce champs est requis' } : {}}
            className='request'
        />
        </div>
      }
      {error
        && <ErrorField message={error}/>
      }

      {field.name === 'content.isSecurityShoes' && watch(field.name) === false && <ErrorField message={'Ne pas accepter la personne sur le chantier'} />}
      {field.name === 'content.isMedicalWorkingHeight' && watch(field.name) === false && <ErrorField message={'Prévenir l’agence employer en manoeuvre'} />}
    </div>
  );
};

export default FormField;
