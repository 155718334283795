import React, { useMemo, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { IoSearch, IoTrash } from 'react-icons/io5';
import { BiMinus, BiPlus } from 'react-icons/bi';
import styles from './quantityPanel.module.scss';
import { IOption } from '~/types/options';
import { QuantityItem } from '~/types/requests';

const QuantityPanel = ({
  options,
  isOpen,
  close,
  quantities,
  handleChangeQuantites,
  setSelected,
  selected,
}: {
  options: IOption[],
  isOpen: boolean,
  close: () => void
  quantities: QuantityItem[],
  handleChangeQuantites: (quantities: QuantityItem[]) => void
  setSelected: any
  selected: QuantityItem | null
}) => {
  const [search, setSearch] = useState('');
  const foundIndex = quantities?.findIndex((item) => item.value === selected?.value);

  const searchResult = useMemo(() => {
    if (!search) return options;
    return options.filter((opt) => opt.label.toLowerCase().includes(search.toLowerCase()));
  }, [options, search]);

  const handleClose = () => {
    setSelected(null);
    setSearch('');
    close();
  };

  const handleAdd = async () => {
    if (!selected?.value) {
      return;
    }
    const updatedQuantities = [...quantities];
    if (foundIndex !== -1) {
      updatedQuantities[foundIndex] = selected;
    } else {
      updatedQuantities.push(selected);
    }
    await handleChangeQuantites(updatedQuantities);
    await handleClose();
  };

  const handleDelete = async () => {
    if (!selected?.value) {
      return;
    }
    const updatedQuantities = [...quantities.filter(
      (q:QuantityItem) => q.value !== selected.value,
    )];
    await handleChangeQuantites(updatedQuantities);
    await handleClose();
  };

  return (
    <div className={`${styles.panel} ${isOpen ? styles.open : ''}`}>
      <button
        type="button"
        onClick={() => handleClose()}
        className={`${styles.close} ${selected?.value ? styles.isSelected : ''}`}
      >
        <FiArrowLeft/>
        <span>Retour</span>
      </button>
      {!selected
      && <>
        <div className={styles.search}>
          <IoSearch />
          <input
            value={search}
            onChange={(e:any) => setSearch(e.target.value)}
          />
        </div>
        <div
          className={styles.options}
        >
          {searchResult.map((opt: any) => (
            <button
              key={opt.value}
              type="button"
              onClick={() => setSelected({ value: opt.value, count: 1 })}
            >
              <span>{opt.label}</span>
            </button>
          ))}
        </div>
        </>
      }
      {selected?.value
        && <div className={styles.selected}>
            <div className={styles.quantity}>
              <h2>{options?.find((opt) => opt.value === selected?.value)?.label}</h2>
              <label>Entrer une quantité</label>
              <div className={styles.input}>
                <button
                  onClick={() => setSelected((prev: any) => ({
                    ...prev,
                    count: prev.count > 1 ? prev.count - 1 : 1,
                  }))}
                >
                  <BiMinus />
                </button>
                <input
                  value={selected?.count || 0}
                  type="number"
                  onChange={(e :any) => setSelected((prev: any) => ({
                    ...prev,
                    count: Number(e.target.value),
                  }))}
                />
                <button
                  onClick={() => setSelected((prev: any) => ({
                    ...prev,
                    count: prev.count + 1,
                  }))}
                >
                  <BiPlus />
                </button>
              </div>
          </div>
          <div className={styles.actions}>
            {foundIndex > -1
              && <button
                type='button'
                className={styles.delete}
                onClick={() => handleDelete()}
              >
                <IoTrash />Supprimer
              </button>
            }
            <button
              type='button'
              onClick={() => handleAdd()}
            >
              {foundIndex > -1
                ? 'Modifier'
                : <>
              <BiPlus />Ajouter
              </>}
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default QuantityPanel;
