import React, { useRef } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { LuPaperclip } from 'react-icons/lu';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { MdDeleteOutline } from 'react-icons/md';
import { GoPencil } from 'react-icons/go';
import styles from './estimates-notes-form.module.scss';
import { INote } from '~/types/estimates';

import ModalNote from '~/components/ModalNote';
import PositionalModal from '~/lib/PositionalModal';

import { useUserProfile } from '~/hooks/auth';
import { downloadFile } from '~/utils';

import {
  useDeleteEstimateNote, useAddEstimateNote, useUpdateEstimateNote,
} from '~/hooks/estimates';

const Note = ({ estimateId, note } : { estimateId: string, note: INote }) => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const modalNoteRef = useRef<any>();
  const { data: user } = useUserProfile();

  const {
    text, author, file, createdAt,
  } = note;
  // type PositionalModalRefHandler = React.ElementRef<typeof PositionalModal>;
  const modalRef = useRef<any>();
  const positionRef = useRef<any>();
  const {
    mutate: deleteEstimateNote,
  } = useDeleteEstimateNote();

  return (
    <>
      <ModalNote
        ref={modalNoteRef}
        documentId={estimateId}
        note={note}
        useUpdateNote={useUpdateEstimateNote}
        handleClose={() => modalNoteRef.current.close()}
      />
      <PositionalModal
        ref={modalRef}
        parent={positionRef}
      >
        <div
          className={styles.noteModal}
          onMouseLeave={() => modalRef.current.close()}
        >
          <p className={styles.update} onClick={() => modalNoteRef.current.open()}>
            <GoPencil size={18} />
            Modifier
          </p>
          <p className={styles.delete} onClick={() => {
            deleteEstimateNote({ estimateId, noteId: note._id });
          }}>
            <MdDeleteOutline size={18} />
            Supprimer
          </p>
        </div>
      </PositionalModal>
      <div className={styles.note}>
        <div>
          <p className={styles.author}>
            {author.profile.firstName} {author.profile.lastName} | {format(createdAt, 'dd.MM.yyyy', { locale: fr })}
          </p>
          <p className={styles.text}>{text}</p>
          {file && (
            <div className={styles.file}>
              <a
                onClick={() => downloadFile(`${process.env.REACT_APP_API_URL}/files/public/${file.path}?token=${token}`, file.name)}
                download
              >
                <LuPaperclip />
                {file.name}
              </a>
            </div>
          )}
        </div>
        {author._id === user?._id && (
          <button
            className='invisible'
            ref={positionRef}
            onClick={() => modalRef.current.open()}
          >
            <HiOutlineDotsHorizontal size={20} />
          </button>
        )}
      </div>
    </>
  );
};

const EstimateNotesForm = ({ estimateId, notes } : { estimateId: string, notes: INote[] }) => {
  const modalNoteRef = useRef<any>();

  return (
    <>
      <ModalNote
        ref={modalNoteRef}
        documentId={estimateId}
        useAddNote={useAddEstimateNote}
        useUpdateNote={useUpdateEstimateNote}
        handleClose={() => modalNoteRef.current.close()}
      />
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.header}>
            <h4>Notes</h4>
            <button onClick={() => modalNoteRef.current.open()}>
              <GoPencil size={20} />
            </button>
          </div>
          <div className={styles.notes}>
            {notes.map((note: INote, index: number) => (
              <Note key={`note-${index}`} estimateId={estimateId} note={note} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimateNotesForm;
