import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import { BsTruck } from 'react-icons/bs';
import { format } from 'date-fns';
import styles from './memberRequests.module.scss';
import { useGetWorksite } from '~/hooks/worksites';
import { useGetRequestsList, useGetWorksiteRequests } from '~/hooks/requests';
import { FormTemplate, IRequest } from '~/types/requests';
import { exportRequest } from '~/api/requests';

export const RequestIcon = ({ type } : { type: string }) => {
  if (type === 'tool') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <path d="M26.5377 7.25037C26.5225 7.22312 26.5013 7.19587 26.4771 7.17468C26.3258 7.02331 26.0805 7.02331 25.9292 7.17468L20.7131 12.3908L18.6575 10.3352L23.8766 5.11609C23.9009 5.09187 23.919 5.06765 23.9372 5.0404C24.0462 4.85574 23.9856 4.6196 23.801 4.51062C20.8281 2.75476 16.9349 3.1574 14.3768 5.71247C12.3485 7.74079 11.6764 10.6168 12.3637 13.2021L3.49354 22.0722C3.40272 22.1631 3.40877 22.3114 3.50262 22.4052L8.64002 27.5426C8.73387 27.6365 8.88524 27.6426 8.97303 27.5517L17.8401 18.6846C20.4285 19.3749 23.3045 18.7058 25.3358 16.6745C27.8909 14.1164 28.2935 10.2232 26.5377 7.25037ZM23.7949 15.1305C22.036 16.8894 19.3962 17.2285 17.2982 16.1477L17.0318 16.4141L17.0288 16.4111L8.73387 24.7091L6.33621 22.3114L13.3021 15.3455C13.3021 15.3455 13.3021 15.3485 13.3052 15.3485L14.9036 13.7501C13.8228 11.6521 14.1619 9.01228 15.9208 7.25339C16.4881 6.68561 17.1712 6.24697 17.9236 5.96745C18.6759 5.68792 19.4798 5.5741 20.2802 5.63376L16.2568 9.65408C16.0751 9.836 15.973 10.0826 15.973 10.3398C15.973 10.5969 16.0751 10.8435 16.2568 11.0255L20.0259 14.7945C20.2078 14.9762 20.4544 15.0783 20.7116 15.0783C20.9687 15.0783 21.2153 14.9762 21.3972 14.7945L25.4176 10.7742C25.5296 12.3424 24.9816 13.9468 23.7949 15.1305Z" fill="#717A97"/>
      </svg>
    );
  }
  if (type === 'material') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
        <path d="M27.125 7.75H21.3125V6.78125C21.3125 6.01046 21.0063 5.27125 20.4613 4.72622C19.9163 4.18119 19.177 3.875 18.4062 3.875H12.5938C11.823 3.875 11.0837 4.18119 10.5387 4.72622C9.99369 5.27125 9.6875 6.01046 9.6875 6.78125V7.75H3.875C3.36114 7.75 2.86833 7.95413 2.50498 8.31748C2.14163 8.68083 1.9375 9.17364 1.9375 9.6875V23.25C1.9375 23.7639 2.14163 24.2567 2.50498 24.62C2.86833 24.9834 3.36114 25.1875 3.875 25.1875H27.125C27.6389 25.1875 28.1317 24.9834 28.495 24.62C28.8584 24.2567 29.0625 23.7639 29.0625 23.25V9.6875C29.0625 9.17364 28.8584 8.68083 28.495 8.31748C28.1317 7.95413 27.6389 7.75 27.125 7.75ZM11.625 6.78125C11.625 6.52432 11.7271 6.27792 11.9087 6.09624C12.0904 5.91456 12.3368 5.8125 12.5938 5.8125H18.4062C18.6632 5.8125 18.9096 5.91456 19.0913 6.09624C19.2729 6.27792 19.375 6.52432 19.375 6.78125V7.75H11.625V6.78125ZM27.125 9.6875V13.5625H23.25V12.5938C23.25 12.3368 23.1479 12.0904 22.9663 11.9087C22.7846 11.7271 22.5382 11.625 22.2812 11.625C22.0243 11.625 21.7779 11.7271 21.5962 11.9087C21.4146 12.0904 21.3125 12.3368 21.3125 12.5938V13.5625H9.6875V12.5938C9.6875 12.3368 9.58544 12.0904 9.40376 11.9087C9.22208 11.7271 8.97568 11.625 8.71875 11.625C8.46182 11.625 8.21542 11.7271 8.03374 11.9087C7.85206 12.0904 7.75 12.3368 7.75 12.5938V13.5625H3.875V9.6875H27.125ZM27.125 23.25H3.875V15.5H7.75V16.4688C7.75 16.7257 7.85206 16.9721 8.03374 17.1538C8.21542 17.3354 8.46182 17.4375 8.71875 17.4375C8.97568 17.4375 9.22208 17.3354 9.40376 17.1538C9.58544 16.9721 9.6875 16.7257 9.6875 16.4688V15.5H21.3125V16.4688C21.3125 16.7257 21.4146 16.9721 21.5962 17.1538C21.7779 17.3354 22.0243 17.4375 22.2812 17.4375C22.5382 17.4375 22.7846 17.3354 22.9663 17.1538C23.1479 16.9721 23.25 16.7257 23.25 16.4688V15.5H27.125V23.25Z" fill="#717A97"/>
      </svg>
    );
  }
  if (type === 'welcome') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.28988 5C6.15043 2.10851 8.82898 0 12 0C15.171 0 17.8496 2.10851 18.7101 5H21V7H19C19 8.75545 18.3538 10.36 17.2863 11.5887C19.5495 13.0805 21 15.6932 21 19V24H3V19C3 15.6905 4.453 13.0756 6.71299 11.5879C5.64591 10.3593 5 8.75508 5 7H3V5H5.28988ZM7.41604 5C7.76402 4.20356 8.31348 3.5153 9 2.99963V4H11V2.10002C11.3231 2.03443 11.6575 2 12 2C12.3425 2 12.6769 2.03443 13 2.10002V4H15V2.99963C15.6865 3.5153 16.236 4.20356 16.584 5H7.41604ZM17 7H7C7 8.42087 7.59267 9.70331 8.54428 10.6136L9 10.3875V11.0004C9.83566 11.6281 10.8744 12 12 12C13.1256 12 14.1643 11.6281 15 11.0004V10.3845L15.4571 10.6123C16.4079 9.70206 17 8.42018 17 7ZM14.5973 13.5023C13.7942 13.8234 12.9177 14 12 14C11.0823 14 10.2058 13.8234 9.40269 13.5023C9.92173 14.3977 10.8905 15 12 15C13.1095 15 14.0783 14.3977 14.5973 13.5023ZM7.2802 13.6544C5.90639 14.8005 5 16.618 5 19V22H11V16.9C9.26481 16.5478 7.85592 15.297 7.2802 13.6544ZM13 16.9V22H19V19C19 16.6212 18.096 14.8048 16.7193 13.6558C16.1433 15.2976 14.7347 16.5479 13 16.9Z" fill="#717A97"/>
      </svg>
    );
  }
  return (
    <BsTruck size={28} color="#717A97" />
  );
};

const MemberRequests = ({
  isWelcome,
}:{
  isWelcome?: boolean,
}) => {
  const { worksiteId } = useParams();
  const { data: worksite } = useGetWorksite(worksiteId || '');
  const { data: requests } = useGetWorksiteRequests(worksiteId || '');
  const { data: list } = useGetRequestsList();
  const [isNew, setIsNew] = useState<boolean>(false);
  const navigate = useNavigate();

  async function handleDownloadRequest(request: any) {
    await exportRequest(request._id, null);
  }

  return (
    <div className={styles.requests}>
      <h1>
        {worksite?.estimate?.worksite.name}
      </h1>
      {isWelcome
        ? <>
            <button
              className={styles.new}
              onClick={() => navigate(`/worksite/${worksiteId}/welcome/welcome`)}
            >
            <BiPlus />
            <span>Nouvelle fiche accueil</span>
          </button>
          <h2>Liste des fiches accueil</h2>
          <div className={styles.list}>
            {list?.templates && requests?.filter((r: IRequest) => r.type === 'welcome')?.map((r: any) => <button
              key={r._id}
              onClick={() => handleDownloadRequest(r)}
            >
              <RequestIcon type={r.type} />
              <div className={styles.infos}>
                <p>
                  {list.templates.find((t: FormTemplate) => t.type === r.type)?.label}
                  {' '}
                  {r?.content?.name}
                </p>
                <p>{format(new Date(r.createdAt), 'dd.MM.yyyy')}</p>
              </div>
            </button>)}
          </div>
        </>
        : <>
      {isNew
        ? <div className={styles.select}>
          {list?.templates.map((t: any) => <Link
            key={t.type}
            to={t.type}
          >
            <RequestIcon type={t.type} />
            <p>{t.label}</p>
          </Link>)}
        </div>
        : <>
          <button
            className={styles.new}
            onClick={() => setIsNew(true)}
          >
            <BiPlus />
            <span>Nouvelle demande</span>
          </button>
          <h2>Mes demandes</h2>
          <div className={styles.list}>
            {list?.templates && requests?.filter((r: IRequest) => r.type !== 'welcome')?.map((r: any) => <button
              key={r._id}
              onClick={() => handleDownloadRequest(r)}
            >
              <RequestIcon type={r.type} />
              <div className={styles.infos}>
                <p>{list.templates.find((t: FormTemplate) => t.type === r.type)?.label}</p>
                <p>{format(new Date(r.createdAt), 'dd.MM.yyyy')}</p>
              </div>
            </button>)}
          </div>
        </>
      }
      </>
      }
    </div>

  );
};

export default MemberRequests;
