import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './ged.module.scss';
import { useGetWorksite, useGetWorksitesList } from '~/hooks/worksites';
import { IOption } from '~/types/options';
import { WorksiteFile } from '~/types/worksites';

const GedCard = ({ card, files = [] }:{ card: IOption, files: WorksiteFile[] }) => (
  <Link to={`${card.value}`} className={styles.card}>
    <div className={styles.count}>
      {files.length}
    </div>
    <h4>{card.label}</h4>
    <p>{files.length > 0 && files[0]?.createdAt ? `Dernière modif : ${format(new Date(files[0].createdAt), 'dd.MM.yyyy')}` : 'Dossier vide'}</p>
  </Link>
);

const Ged = ({ isDesktop = false }: { isDesktop?: boolean }) => {
  const { id, worksiteId } = useParams();
  const {
    data: worksite,
  } = useGetWorksite(id || worksiteId || '');

  const {
    data: list = {},
  } = useGetWorksitesList();

  return (
    <div className={`${styles.ged} ${isDesktop ? styles.desktop : ''}`}>
      {list?.filesTypes?.map((f: IOption) => <GedCard
        key={f.value}
        card={f}
        files={worksite?.files
          ?.sort((a: WorksiteFile, b: WorksiteFile) => Number(a.createdAt) - Number(b.createdAt))
          ?.filter((wf:WorksiteFile) => wf.type === f.value) || []}
      />)}
    </div>
  );
};

export default Ged;
