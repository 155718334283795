import React, { useMemo, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import { IoCheckmark } from 'react-icons/io5';
import styles from './requestForm.module.scss';
import FormField from '~/components/FormField';
import QuantityPanel from '~/components/QuantityPanel';
import { QuantityItem } from '~/types/requests';
import { IOption } from '~/types/options';

const presentationField = {
  name: 'content.isPresentation',
  label: 'Présentation générale du site / chantier (affichage obligatoire / toilettes / vestiaires / zone fumeurs...)',
  type: 'boolean',
  section: 'infos',
  title: 'Informations',
};

const RequestForm = ({
  template,
  onSubmit,
  isLoading,
}: {
  template: any,
  onSubmit: (data: FieldValues) => void
  isLoading: boolean,
}) => {
  const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const {
    handleSubmit, control, watch, setValue, formState: { errors },
  } = useFormContext();

  const form = watch();
  const quantities = watch('content.quantites') || [];

  const submitIsDisabled = useMemo(() => {
    let disabled = true;
    if (form.comment
      || Object.keys(form?.content).length > 0) {
      disabled = false;
    }
    return disabled;
  }, [form]);

  const fieldSections = useMemo(() => {
    const sections: any[] = [];
    if (!template?.fields) {
      return sections;
    }
    template?.fields.forEach((field: any) => {
      if (!sections.find((s: any) => s.section === field?.section)) {
        let col = '';
        if (field.section === 'Equipements OBLIGATOIRES fournis par l’agence d’intérim') {
          col = 'col-3';
        }
        if (field.section === 'formation') {
          col = 'col-1';
        }
        if (field.section === 'infos') {
          col = 'col-3';
        }
        sections.push({
          title: field.title,
          fields: template?.fields.filter((s: any) => s.section === field?.section && s.name !== 'content.isPresentation'),
          section: field.section,
          col,
        });
      }
    });
    return sections;
  }, [template?.fields]);

  function handleResetEPI() {
    [
      'content.isHarness',
      'content.isLanyard',
      'content.isLanyard2',
    ].forEach((name: string) => {
      setValue(name, null);
    });
  }

  return (
    <>
      <div className={styles.form}>
        {(template?.type === 'transport')
        && <div className={styles.fields}>
            {template?.fields?.map((f: any) => (
              <FormField
                key={f.name}
                control={control}
                field={f}
                errors={errors}
              />
            ))}
          </div>
        }
        {(template?.type === 'welcome')
        && <div className={styles.sections}>
            {fieldSections?.map((s: any) => (
              <div
                key={s.section}
                className={`${styles.section} ${s.col ? styles[s.col] : ''}`}
              >
                <h3>{s.title}</h3>
                {s.section === 'EPI'
                  && <div className={styles.reset}>
                    <div
                      className={styles.check}
                      onClick={() => handleResetEPI()}
                    >
                    {form.content.isHarness === null
                     && form.content.isLanyard === null
                     && form.content.isLanyard2 === null
                    && <IoCheckmark />
                    }
                    </div>
                    <p>Sans objet - travail au sol uniquement ou en sécurité collective</p>
                  </div>
                }
                {s.section === 'infos'
                  && <FormField
                    control={control}
                    field={presentationField}
                    errors={errors}
                  />
                }
                <div className={styles.fields}>
                {s?.fields?.map((f: any) => (
                  <FormField
                    key={f.name}
                    control={control}
                    field={f}
                    errors={errors}
                  />
                ))}
                </div>
                <hr/>
              </div>
            ))}
          </div>
        }
        {template?.type === 'material'
        && <div className={styles.material}>
            <button
              type='button'
              className={styles.add}
              onClick={() => setPanelIsOpen(true)}
            >
              <BiPlus />
              Ajouter du matériel manquant
            </button>
            <div className={styles.quantities}>
              {quantities.map((q: QuantityItem) => <button
                key={q.value}
                onClick={async () => {
                  await setSelected(q);
                  await setPanelIsOpen(true);
                }}
              >
                <p>
                  {template.options.find((opt: IOption) => opt.value === q.value)?.label}
                  <br/>
                  <span>
                    {template.options.find((opt: IOption) => opt.value === q.value)?.ref}
                  </span>
                </p>
                <p>{q.count}</p>
              </button>)}
            </div>
          </div>
        }
        <div className={styles.comment}>
          <FormField
            control={control}
            field={{
              label: 'Commentaire',
              name: 'comment',
              type: 'textarea',
            }}
            errors={errors}
          />
        </div>
        {!isLoading
        && <button
          type="button"
          className={styles.submit}
          disabled={submitIsDisabled}
          onClick={handleSubmit(onSubmit)}
        >
          Envoyer
        </button>
        }
      </div>
      {template?.type === 'material'
        && <QuantityPanel
          isOpen={panelIsOpen}
          options={template.options}
          quantities={quantities}
          handleChangeQuantites={(array: QuantityItem[]) => setValue('content.quantites', array)}
          close={() => setPanelIsOpen(false)}
          setSelected={setSelected}
          selected={selected}
        />
      }
    </>
  );
};

export default RequestForm;
