import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import styles from './estimates-table.module.scss';
import { IEstimate } from '~/types/estimates';
import { IOption } from '~/types/options';
import { useGetEstimatesList } from '~/hooks/estimates';

const columns = [{
  header: 'Nom du chantier',
  accessorKey: 'worksite.name',
}, {
  header: 'Nom de l\'entreprise',
  accessorKey: 'client.name',
}, {
  header: 'Agence',
  accessorKey: 'agency.name',
}, {
  header: 'Département',
  accessorKey: 'worksite.department',
}, {
  header: 'Ville',
  accessorKey: 'worksite.city',
  cell: ({ getValue }: any) => (

      <span className={styles.city}>
       {getValue()}
      </span>

  ),
}, {
  header: 'Statut',
  accessorKey: 'status',
  cell: ({ getValue, status }: any) => {
    const opt = status
      .find((d: IOption) => d.value === getValue());

    return (
      <>
        <div className={styles.dot} style={opt?.color ? { backgroundColor: opt.color } : {}} />
        {opt?.label || getValue() }
      </>
    );
  },
}];

const EstimatesTable = (
  {
    estimates,
    handleSorting,
    baseTo = '/estimates',
  } : {
    estimates: IEstimate[],
    handleSorting?: any,
    baseTo?: string,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  const { data: list = {} } = useGetEstimatesList();
  const { status = [] } = list;

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0] || null);
  }, [sorting]);

  const data = useMemo(() => estimates, [estimates]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IEstimate>) => {
    navigate(`${baseTo}/${row.original._id}`);
  };

  return (
    <div className={styles.estimatesTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                 <p>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {typeof header.column.getIsSorted() === 'string' ? (
                    {
                      asc: <IoChevronUpOutline />,
                      desc: <IoChevronDownOutline />,
                    }[header.column.getIsSorted() as string]
                  ) : (
                    <span className={styles.sortIcons}>
                      <IoChevronUpOutline />
                      <IoChevronDownOutline />
                    </span>
                  )}
                 </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className={`${styles.cell} ${styles[cell.column.id]}`}>
                    <div className={styles.content}>
                      {cell.column.id === 'status' ? (
                        <>
                          <span>
                            {flexRender(
                              cell.column.columnDef.cell,
                              {
                                ...cell.getContext(),
                                status,
                              },
                            )}
                          </span>
                        </>
                      ) : flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </div>
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EstimatesTable;
