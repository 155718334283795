import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import styles from './worksiteHeader.module.scss';
import { IWorksite } from '~/types/worksites';

const WorksiteHeader = ({ worksite }:{ worksite?: IWorksite | null }) => {
  const navigate = useNavigate();
  return (
  <div className={styles.header}>
    <div className={styles.content}>
      <div className={styles.title}>
        <a
          onClick={() => navigate(-1)}
        >
          <FiArrowLeft />
        </a>
        <h1>{worksite?.estimate?.worksite?.name}</h1>
        <h2>{worksite?.client?.name}</h2>
      </div>
    </div>
  </div>
  );
};

export default WorksiteHeader;
